import { GetPaginateMateriaPrimaRequest } from "../../requests/materiaPrima/GetPaginateMateriaPrimaRequest";
import { GetTarifasPaginateMateriaPrimaRequest } from "../../requests/materiaPrima/GetTarifasPaginateMateriaPrimaRequest";
import { InsertMateriaPrimaRequest } from "../../requests/materiaPrima/InsertMateriaPrimaRequest";
import { UpdateMateriaPrimaRequest } from "../../requests/materiaPrima/UpdateMateriaPrimaRequest";
import { APICore } from "./apiCore";

const api = new APICore();

export function get(){
    const baseUrl = "materiaprima";
    return api.get(`${baseUrl}`, null);
}

export function getPaginate(params: GetPaginateMateriaPrimaRequest) {
    const baseUrl = "materiaprima/paginate";
    return api.get(`${baseUrl}`, params);
}

export function getTarifasPaginate(params: GetTarifasPaginateMateriaPrimaRequest) {
    const baseUrl = "materiaprima/tarifa/paginate";
    return api.get(`${baseUrl}`, params);
}

export function getById(params: {id: number}) {
    const baseUrl = "materiaprima/";
    return api.get(`${baseUrl}${params.id}`, null);
}

export function getColores() {
    const baseUrl = "materiaprima/color";
    return api.get(`${baseUrl}`, null);
}

export function getTipos() {
    const baseUrl = "materiaprima/tipo";
    return api.get(`${baseUrl}`, null);
}

export function getCategoriasByIdTipo(params: {idTipoMateriaPrima: number}) {
    const baseUrl = "materiaprima/categoria/";
    return api.get(`${baseUrl}${params.idTipoMateriaPrima}`, null);
}

export function getSubCategoriasByIdCategoria(params: {idCategoriaMateriaPrima: number}) {
    const baseUrl = "materiaprima/subcategoria/";
    return api.get(`${baseUrl}${params.idCategoriaMateriaPrima}`, null);
}

export function insert(params: InsertMateriaPrimaRequest){
    const baseUrl = "materiaprima";
    return api.create(`${baseUrl}`, params);
}

export function update(params: UpdateMateriaPrimaRequest){
    const baseUrl = "materiaprima";
    return api.updatePatch(`${baseUrl}/${params.id}`, params);
}

export function remove(params: {id: number}){
    const baseUrl = "materiaprima";
    return api.delete(`${baseUrl}/${params.id}`)
  }