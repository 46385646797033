// constants
import { GetAllPaginateUsuarioRequest } from "../../requests/usuario/GetAllPaginateUsuarioRequest";
import { InsertUsuarioRequest } from "../../requests/usuario/InsertUsuarioRequest";
import { UpdateUsuarioRequest } from "../../requests/usuario/UpdateUsuarioRequest";
import { UsuarioActionTypes } from "./constants";

export interface UsuarioActionType {
  type:
    | UsuarioActionTypes.API_RESPONSE_SUCCESS
    | UsuarioActionTypes.API_RESPONSE_ERROR
    | UsuarioActionTypes.LOGIN_DASHBOARD_USUARIO
    | UsuarioActionTypes.GET_PAGINATE_USUARIO
    | UsuarioActionTypes.GET_PERFILES_USUARIO
    | UsuarioActionTypes.INSERT_USUARIO
    | UsuarioActionTypes.UPDATE_USUARIO
    | UsuarioActionTypes.DELETE_USUARIO
    | UsuarioActionTypes.RESET
  payload: {} | string;
}

interface UserData {
  id: number;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  role: string;
  token: string;
}

// common success
export const usuarioApiResponseSuccess = (
  actionType: string,
  data: UserData | {}
): UsuarioActionType => ({
  type: UsuarioActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const usuarioApiResponseError = (
  actionType: string,
  error: string
): UsuarioActionType => ({
  type: UsuarioActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const loginDashboardUsuario = (
  correo: string,
  password: string
): UsuarioActionType => ({
  type: UsuarioActionTypes.LOGIN_DASHBOARD_USUARIO,
  payload: { correo, password },
});

export const getPaginateUsuario = (request: GetAllPaginateUsuarioRequest): UsuarioActionType => ({
  type: UsuarioActionTypes.GET_PAGINATE_USUARIO,
  payload: request,
});

export const getPerfiles = (): UsuarioActionType => ({
  type: UsuarioActionTypes.GET_PERFILES_USUARIO,
  payload: { },
})

export const insert = (request: InsertUsuarioRequest): UsuarioActionType => ({
  type: UsuarioActionTypes.INSERT_USUARIO,
  payload: request,
})

export const update = (request: UpdateUsuarioRequest): UsuarioActionType => ({
  type: UsuarioActionTypes.UPDATE_USUARIO,
  payload: request,
})

export const deleteUsuario = (id: number): UsuarioActionType => ({
  type: UsuarioActionTypes.DELETE_USUARIO,
  payload: { id },
})

export const resetAuth = (): UsuarioActionType => ({
  type: UsuarioActionTypes.RESET,
  payload: {},
});
