import { GetPaginateMateriaPrimaRequest } from "../../requests/materiaPrima/GetPaginateMateriaPrimaRequest";
import { GetTarifasPaginateMateriaPrimaRequest } from "../../requests/materiaPrima/GetTarifasPaginateMateriaPrimaRequest";
import { InsertMateriaPrimaRequest } from "../../requests/materiaPrima/InsertMateriaPrimaRequest";
import { UpdateMateriaPrimaRequest } from "../../requests/materiaPrima/UpdateMateriaPrimaRequest";
import { MateriaPrimaActionTypes } from "./constants";

export interface MateriaPrimaActionType {
    type:
    | MateriaPrimaActionTypes.API_RESPONSE_SUCCESS
    | MateriaPrimaActionTypes.API_RESPONSE_ERROR
    | MateriaPrimaActionTypes.GET_PAGINATE_MATERIA_PRIMA
    | MateriaPrimaActionTypes.GET_TARIFAS_PAGINATE_MATERIA_PRIMA
    | MateriaPrimaActionTypes.GET_COLORES_MATERIA_PRIMA
    | MateriaPrimaActionTypes.GET_TIPOS_MATERIA_PRIMA
    | MateriaPrimaActionTypes.GET_CATEGORIAS_MATERIA_PRIMA
    | MateriaPrimaActionTypes.GET_SUB_CATEGORIAS_MATERIA_PRIMA
    | MateriaPrimaActionTypes.INSERT_MATERIA_PRIMA
    | MateriaPrimaActionTypes.UPDATE_MATERIA_PRIMA
    | MateriaPrimaActionTypes.DELETE_MATERIA_PRIMA
    payload: {} | string;
}

// common success
export const materiaPrimaApiResponseSuccess = (
    actionType: string,
    data: any
): MateriaPrimaActionType => ({
    type: MateriaPrimaActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const materiaPrimaApiResponseError = (
    actionType: string,
    error: string
): MateriaPrimaActionType => ({
    type: MateriaPrimaActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getPaginateMateriaPrima = (request: GetPaginateMateriaPrimaRequest): MateriaPrimaActionType => ({
    type: MateriaPrimaActionTypes.GET_PAGINATE_MATERIA_PRIMA,
    payload: request,
});

export const getTarifasPaginateMateriaPrima = (request: GetTarifasPaginateMateriaPrimaRequest): MateriaPrimaActionType => ({
  type: MateriaPrimaActionTypes.GET_TARIFAS_PAGINATE_MATERIA_PRIMA,
  payload: request,
});

export const getColores = (): MateriaPrimaActionType => ({
  type: MateriaPrimaActionTypes.GET_COLORES_MATERIA_PRIMA,
  payload: {},
});

export const getTipos = (): MateriaPrimaActionType => ({
  type: MateriaPrimaActionTypes.GET_TIPOS_MATERIA_PRIMA,
  payload: {},
});

export const getCategoriasByIdTipoMateriaPrima = (idTipoMateriaPrima: number): MateriaPrimaActionType => ({
  type: MateriaPrimaActionTypes.GET_CATEGORIAS_MATERIA_PRIMA,
  payload: { idTipoMateriaPrima },
});

export const getSubCategoriasByIdCategoriaMateriaPrima = (idCategoriaMateriaPrima: number): MateriaPrimaActionType => ({
  type: MateriaPrimaActionTypes.GET_SUB_CATEGORIAS_MATERIA_PRIMA,
  payload: { idCategoriaMateriaPrima },
});

export const insertMateriaPrima = (request: InsertMateriaPrimaRequest): MateriaPrimaActionType => ({
    type: MateriaPrimaActionTypes.INSERT_MATERIA_PRIMA,
    payload: request,
  })
  
export const updateMateriaPrima = (request: UpdateMateriaPrimaRequest): MateriaPrimaActionType => ({
    type: MateriaPrimaActionTypes.UPDATE_MATERIA_PRIMA,
    payload: request,
  })

  export const deleteMateriaPrima = (id: number): MateriaPrimaActionType => ({
    type: MateriaPrimaActionTypes.DELETE_MATERIA_PRIMA,
    payload: { id },
  })