export enum MateriaPrimaActionTypes {
    API_RESPONSE_SUCCESS = "@@materiaPrima/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR = "@@materiaPrima/API_RESPONSE_ERROR",

    GET_PAGINATE_MATERIA_PRIMA = "@@materiaPrima/GET_PAGINATE_MATERIA_PRIMA",
    GET_TARIFAS_PAGINATE_MATERIA_PRIMA = "@@materiaPrima/GET_TARIFAS_PAGINATE_MATERIA_PRIMA",
    GET_COLORES_MATERIA_PRIMA = "@@materiaPrima/GET_COLORES_MATERIA_PRIMA",
    GET_TIPOS_MATERIA_PRIMA = "@@materiaPrima/GET_TIPOS_MATERIA_PRIMA",
    GET_CATEGORIAS_MATERIA_PRIMA = "@@materiaPrima/GET_CATEGORIAS_MATERIA_PRIMA",
    GET_SUB_CATEGORIAS_MATERIA_PRIMA = "@@materiaPrima/GET_SUB_CATEGORIAS_MATERIA_PRIMA",
    INSERT_MATERIA_PRIMA = "@@materiaPrima/INSERT_MATERIA_PRIMA",
    UPDATE_MATERIA_PRIMA = "@@materiaPrima/UPDATE_MATERIA_PRIMA",
    DELETE_MATERIA_PRIMA = "@@materiaPrima/DELETE_MATERIA_PRIMA",
  }