import { all } from "redux-saga/effects";

import usuarioSaga from "./usuario/saga";
import materiaPrimaSaga from "./materiaPrima/saga";
import productoSaga from "./producto/saga";
import layoutSaga from "./layout/saga";

export default function* rootSaga() {
  yield all([usuarioSaga(), layoutSaga(), materiaPrimaSaga(), productoSaga()]);
}
