import { GetPaginateProductoRequest } from "../../requests/producto/GetPaginateProductoRequest";
import { InsertProductoRequest } from "../../requests/producto/InsertProductoRequest";
import { UpdateProductoRequest } from "../../requests/producto/UpdateProductoRequest";
import { ProductoActionTypes } from "./constants";

export interface ProductoActionType {
    type:
    | ProductoActionTypes.API_RESPONSE_SUCCESS
    | ProductoActionTypes.API_RESPONSE_ERROR
    | ProductoActionTypes.GET_PAGINATE_PRODUCTO
    | ProductoActionTypes.GET_CATEGORIAS_PRODUCTO
    | ProductoActionTypes.GET_TIPOS_DESCUENTO_PRODUCTO
    | ProductoActionTypes.GET_UNIDADES_PRODUCTO
    | ProductoActionTypes.INSERT_PRODUCTO
    | ProductoActionTypes.UPDATE_PRODUCTO
    | ProductoActionTypes.DELETE_PRODUCTO
    payload: {} | string;
}

// common success
export const productoApiResponseSuccess = (
    actionType: string,
    data: any
): ProductoActionType => ({
    type: ProductoActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const productoApiResponseError = (
    actionType: string,
    error: string
): ProductoActionType => ({
    type: ProductoActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getPaginateProducto = (request: GetPaginateProductoRequest): ProductoActionType => ({
    type: ProductoActionTypes.GET_PAGINATE_PRODUCTO,
    payload: request,
});

export const getCategoriasProducto = (): ProductoActionType => ({
  type: ProductoActionTypes.GET_CATEGORIAS_PRODUCTO,
  payload: {},
});

export const getTiposDescuentoProducto = (): ProductoActionType => ({
  type: ProductoActionTypes.GET_TIPOS_DESCUENTO_PRODUCTO,
  payload: {},
});

export const getUnidadesProducto = (): ProductoActionType => ({
  type: ProductoActionTypes.GET_UNIDADES_PRODUCTO,
  payload: { },
});

export const insertProducto = (request: InsertProductoRequest): ProductoActionType => ({
    type: ProductoActionTypes.INSERT_PRODUCTO,
    payload: request,
  })
  
export const updateProducto = (request: UpdateProductoRequest): ProductoActionType => ({
    type: ProductoActionTypes.UPDATE_PRODUCTO,
    payload: request,
  })

  export const deleteProducto = (id: number): ProductoActionType => ({
    type: ProductoActionTypes.DELETE_PRODUCTO,
    payload: { id },
  })