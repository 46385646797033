// action constants
import { LayoutActionTypes, LayoutStateTypes } from "./constants";

// app constants
import {
  TwoColumnTheme,
  LayoutMode,
  // SidenavUser,
  LayoutTypes,
  LayoutColor,
  LayoutWidth,
  MenuPositions,
  SideBarTheme,
  SideBarTypes,
  TopbarTheme,
} from "../../constants/layout";

// utils
import { getLayoutConfigs } from "../../utils";
import { UsuarioModalMode } from "../../modulos/usuario/UsuarioModal";
import { TipoMensaje } from "../../components/modals/MensajeModal";
import { MateriaPrimaModalMode } from "../../modulos/materia-prima/MateriaPrimaModal";



const INIT_STATE = {
  twoColumnTheme: TwoColumnTheme.TWOCOLUMN_LIGHT,
  layoutMode: LayoutMode.LAYOUT_DEFAULT,
  sidenavUser: false,
  layoutType: LayoutTypes.LAYOUT_VERTICAL,
  layoutColor: LayoutColor.LAYOUT_COLOR_LIGHT,
  layoutWidth: LayoutWidth.LAYOUT_WIDTH_FLUID,
  menuPosition: MenuPositions.MENU_POSITION_FIXED,
  leftSideBarTheme: SideBarTheme.LEFT_SIDEBAR_THEME_LIGHT,
  leftSideBarType: SideBarTypes.LEFT_SIDEBAR_TYPE_DEFAULT,
  showTwoToneIcons: false,
  showSidebarUserInfo: false,
  showSpinner: false,
  mensajeModal: {
    mensaje: "",
    tipo: TipoMensaje.success,
    show: false
  },
  mensajeConfirmacionModal: {
    mensaje: "",
    show: false,
    confirmar: () => {}
  },
  usuarioModal: {
    mode: UsuarioModalMode.Visualizar,
    show: false,
    usuario: { id: 0, nombres: '', apellidos: '', correo: '', celular: '', dni: '', foto: '', perfiles: [], permisos: [] },
    modulos: []
  },
  materiaPrimaModal: {
    mode: MateriaPrimaModalMode.Visualizar,
    show: false,
    materiaPrima: { id: 0, idSubCategoriaMateriaPrima: 0, idCategoriaMateriaPrima: 0, idTipoMateriaPrima: 0, idColor: 0, nombre: '', descripcion: '', imagen: '' }
  },
  topbarTheme: TopbarTheme.TOPBAR_THEME_DARK,
  isOpenRightSideBar: false,
};

interface LayoutActionType {
  type:
  | LayoutActionTypes.CHANGE_LAYOUT_MODE
  | LayoutActionTypes.CHANGE_TWOCOLUMN_THEME
  | LayoutActionTypes.CHANGE_LAYOUT
  | LayoutActionTypes.CHANGE_LAYOUT_COLOR
  | LayoutActionTypes.CHANGE_LAYOUT_WIDTH
  | LayoutActionTypes.CHANGE_MENU_POSITIONS
  | LayoutActionTypes.CHANGE_SIDEBAR_THEME
  | LayoutActionTypes.CHANGE_SIDEBAR_TYPE
  | LayoutActionTypes.TOGGLE_SIDEBAR_USER_INFO
  | LayoutActionTypes.CHANGE_TOPBAR_THEME
  | LayoutActionTypes.TOGGLE_TWO_TONE_ICONS
  | LayoutActionTypes.SHOW_RIGHT_SIDEBAR
  | LayoutActionTypes.TOGGLE_SPINNER
  | LayoutActionTypes.HIDE_RIGHT_SIDEBAR
  | LayoutActionTypes.API_RESPONSE_SUCCESS
  | LayoutActionTypes.API_RESPONSE_ERROR
  | LayoutActionTypes.OPEN_REGISTER_USUARIO_MODAL
  | LayoutActionTypes.OPEN_UPDATE_USUARIO_MODAL
  | LayoutActionTypes.CLOSE_USUARIO_MODAL
  | LayoutActionTypes.OPEN_REGISTER_MATERIA_PRIMA_MODAL
  | LayoutActionTypes.OPEN_UPDATE_MATERIA_PRIMA_MODAL
  | LayoutActionTypes.CLOSE_MATERIA_PRIMA_MODAL
  | LayoutActionTypes.OPEN_MENSAJE_MODAL
  | LayoutActionTypes.CLOSE_MENSAJE_MODAL
  | LayoutActionTypes.OPEN_MENSAJE_CONFIRMACION_MODAL
  | LayoutActionTypes.CLOSE_MENSAJE_CONFIRMACION_MODAL
  payload: {
    actionType?: string;
    data: any;
    error?: any;
  } | any;
}

const Layout = (
  state: LayoutStateTypes = INIT_STATE,
  action: LayoutActionType
) => {
  switch (action.type) {
    case LayoutActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case LayoutActionTypes.OPEN_REGISTER_USUARIO_MODAL:
          return {
            ...state,
            showSpinner: false,
            usuarioModal: { ...state.usuarioModal, show: true, usuario: action.payload.data.usuario, modulos: action.payload.data.modulos }
          }
        case LayoutActionTypes.OPEN_UPDATE_USUARIO_MODAL:
          return {
            ...state,
            showSpinner: false,
            usuarioModal: { ...state.usuarioModal, show: true, usuario: action.payload.data.usuario, modulos: action.payload.data.modulos }
          }
        case LayoutActionTypes.OPEN_REGISTER_MATERIA_PRIMA_MODAL:
          return {
            ...state,
            showSpinner: false,
            materiaPrimaModal: { ...state.materiaPrimaModal, show: true }
          }
        case LayoutActionTypes.OPEN_UPDATE_MATERIA_PRIMA_MODAL:
          return {
            ...state,
            showSpinner: false,
            materiaPrimaModal: { ...state.materiaPrimaModal, show: true, materiaPrima: action.payload.data }
          }
        default:
          return { ...state };
      }

    case LayoutActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case LayoutActionTypes.OPEN_REGISTER_USUARIO_MODAL:
        case LayoutActionTypes.OPEN_UPDATE_USUARIO_MODAL:
          return {
            ...state,
            showSpinner: false,
            usuarioModal: INIT_STATE.usuarioModal
          }
        default:
          return { ...state };
      }

    case LayoutActionTypes.CHANGE_TWOCOLUMN_THEME:
      return {
        ...state,
        twoColumnTheme: action.payload,
      };

    case LayoutActionTypes.CHANGE_LAYOUT:
      return {
        ...state,
        layoutType: action.payload,
      };

    case LayoutActionTypes.CHANGE_LAYOUT_MODE:
      return {
        ...state,
        layoutMode: action.payload,
      };

    case LayoutActionTypes.CHANGE_LAYOUT_COLOR:
      return {
        ...state,
        layoutColor: action.payload,
      };

    case LayoutActionTypes.CHANGE_LAYOUT_WIDTH:
      const layoutConfig = getLayoutConfigs(action.payload.data);
      return {
        ...state,
        layoutWidth: action.payload,
        ...layoutConfig,
      };

    case LayoutActionTypes.CHANGE_MENU_POSITIONS:
      return {
        ...state,
        menuPosition: action.payload,
      };

    case LayoutActionTypes.CHANGE_SIDEBAR_THEME:
      return {
        ...state,
        leftSideBarTheme: action.payload,
      };

    case LayoutActionTypes.CHANGE_SIDEBAR_TYPE:
      return {
        ...state,
        leftSideBarType: action.payload,
      };

    case LayoutActionTypes.TOGGLE_SIDEBAR_USER_INFO:
      return {
        ...state,
        showSidebarUserInfo: action.payload,
      };

    case LayoutActionTypes.CHANGE_TOPBAR_THEME:
      return {
        ...state,
        topbarTheme: action.payload,
      };

    case LayoutActionTypes.TOGGLE_SPINNER:
      return {
        ...state,
        showSpinner: action.payload,
      };

    case LayoutActionTypes.TOGGLE_TWO_TONE_ICONS:
      return {
        ...state,
        showTwoToneIcons: action.payload,
      };

    case LayoutActionTypes.SHOW_RIGHT_SIDEBAR:
      return {
        ...state,
        isOpenRightSideBar: true,
      };

    case LayoutActionTypes.HIDE_RIGHT_SIDEBAR:
      return {
        ...state,
        isOpenRightSideBar: false,
      };

    case LayoutActionTypes.OPEN_MENSAJE_MODAL: {
      return {
        ...state,
        showSpinner: false,
        mensajeModal: { mensaje: action.payload.mensaje, tipo: action.payload.tipo, show: true },
        usuarioModal: INIT_STATE.usuarioModal
      }
    }

    case LayoutActionTypes.CLOSE_MENSAJE_MODAL: {
      return {
        ...state,
        mensajeModal: INIT_STATE.mensajeModal
      }
    }

    case LayoutActionTypes.OPEN_MENSAJE_CONFIRMACION_MODAL: {
      return {
        ...state,
        mensajeConfirmacionModal: { mensaje: "", show: true, confirmar: action.payload.confirmar },
      }
    }

    case LayoutActionTypes.CLOSE_MENSAJE_CONFIRMACION_MODAL: {
      return {
        ...state,
        mensajeConfirmacionModal: INIT_STATE.mensajeModal
      }
    }

    case LayoutActionTypes.OPEN_REGISTER_USUARIO_MODAL:
      return {
        ...state,
        showSpinner: true,
        usuarioModal: { show: false, mode: UsuarioModalMode.Registrar, usuario: INIT_STATE.usuarioModal.usuario, modulos: [] }
      }
    case LayoutActionTypes.OPEN_UPDATE_USUARIO_MODAL:
      return {
        ...state,
        showSpinner: true,
        usuarioModal: { show: false, mode: action.payload.mode, usuario: INIT_STATE.usuarioModal.usuario, modulos: [] }
      }
    case LayoutActionTypes.OPEN_REGISTER_MATERIA_PRIMA_MODAL:
      return {
        ...state,
        showSpinner: true,
        materiaPrimaModal: { show: false, mode: MateriaPrimaModalMode.Registrar, materiaPrima: INIT_STATE.materiaPrimaModal.materiaPrima }
      }
    case LayoutActionTypes.OPEN_UPDATE_MATERIA_PRIMA_MODAL:
      return {
        ...state,
        showSpinner: true,
        materiaPrimaModal: { show: false, mode: action.payload.mode, materiaPrima: INIT_STATE.materiaPrimaModal.materiaPrima }
      }
    case LayoutActionTypes.CLOSE_USUARIO_MODAL:
      return {
        ...state,
        usuarioModal: INIT_STATE.usuarioModal,
      }
      case LayoutActionTypes.CLOSE_MATERIA_PRIMA_MODAL:
        return {
          ...state,
          materiaPrimaModal: INIT_STATE.materiaPrimaModal,
        }

    default:
      return state;
  }
};

export default Layout;
