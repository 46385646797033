import { GetPaginateProductoRequest } from "../../requests/producto/GetPaginateProductoRequest";
import { InsertProductoRequest } from "../../requests/producto/InsertProductoRequest";
import { UpdateProductoRequest } from "../../requests/producto/UpdateProductoRequest";
import { APICore } from "./apiCore";

const api = new APICore();

export function get(){
    const baseUrl = "producto";
    return api.get(`${baseUrl}`, null);
}

export function getAllPaginate(params: GetPaginateProductoRequest) {
    const baseUrl = "producto/paginate";
    return api.get(`${baseUrl}`, params);
}

export function getById(params: {id: number}) {
    const baseUrl = "producto/";
    return api.get(`${baseUrl}${params.id}`, null);
}

export function getUnidades() {
    const baseUrl = "producto/unidad";
    return api.get(`${baseUrl}`, null);
}

export function getTipos() {
    const baseUrl = "producto/tipodescuento";
    return api.get(`${baseUrl}`, null);
}

export function getCategorias() {
    const baseUrl = "producto/categoria";
    return api.get(`${baseUrl}`, null);
}

export function insert(params: InsertProductoRequest){
    const baseUrl = "producto";
    return api.create(`${baseUrl}`, params);
}

export function update(params: UpdateProductoRequest){
    const baseUrl = "producto";
    return api.updatePatch(`${baseUrl}/${params.id}`, params);
}

export function remove(params: {id: number}){
    const baseUrl = "producto";
    return api.delete(`${baseUrl}/${params.id}`)
  }