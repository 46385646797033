import { SagaIterator } from "redux-saga";
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { MateriaPrima, Usuario } from "../../helpers";
import { UsuarioModalMode } from "../../modulos/usuario/UsuarioModal";
import { PermisoResponse } from "../../responses/usuario/PermisoResponse";
import { UsuarioResponse } from "../../responses/usuario/UsuarioResponse";
import { materiaPrimaApiResponseSuccess } from "../materiaPrima/actions";
import { MateriaPrimaActionTypes } from "../materiaPrima/constants";
import { layoutApiResponseError, layoutApiResponseSuccess } from "./actions";

// constants
import { LayoutActionTypes } from "./constants";

interface GetByIdData {
  payload: { id: number, mode: UsuarioModalMode };
  type: string;
}

/**
 * Toggle the class on body
 * @param {*} cssClass
 */
function manageHtmlClass(cssClass: string, action = "toggle") {
  switch (action) {
    case "add":
      if (document.getElementsByTagName("html")[0])
        document.getElementsByTagName("html")[0].classList.add(cssClass);
      break;
    case "remove":
      if (document.getElementsByTagName("html")[0])
        document.getElementsByTagName("html")[0].classList.remove(cssClass);
      break;
    default:
      if (document.getElementsByTagName("html")[0])
        document.getElementsByTagName("html")[0].classList.toggle(cssClass);
      break;
  }
  return true;
}

/**
 * Obtiene un usuario
 */
 function* openRegisterUsuarioModal(): SagaIterator {
  try {
    const modulosResponse = yield call(Usuario.getModulos);
    let usuario: UsuarioResponse = { id: 0, nombres: '', apellidos: '', correo: '', celular: '', dni: '', foto: '', perfiles: [], permisos: [] };
    modulosResponse.data.forEach((element: any)=>{
      usuario.permisos.push({idModulo: element.id, visualizar: false, registrar: false, modificar: false, eliminar: false})
    })
    yield put(layoutApiResponseSuccess(LayoutActionTypes.OPEN_REGISTER_USUARIO_MODAL, { modulos: modulosResponse.data, usuario }))
  } catch (error: any) {
    yield put(layoutApiResponseError(LayoutActionTypes.OPEN_REGISTER_USUARIO_MODAL, error));
  }
}

/**
 * Obtiene un usuario
 */
 function* openUpdateUsuarioModal(request : GetByIdData): SagaIterator {
  try {
    const [modulosResponse, usuarioResponse] = yield all([call(Usuario.getModulos), call(Usuario.getById, request.payload)])
    let usuario: UsuarioResponse = { id: usuarioResponse.data.id ?? 0, nombres: usuarioResponse.data.nombres ?? '', apellidos: usuarioResponse.data.apellidos ?? '', correo: usuarioResponse.data.correo ?? '', celular: usuarioResponse.data.celular ?? '', dni: usuarioResponse.data.dni ?? '', foto: usuarioResponse.data.foto ?? '', perfiles: usuarioResponse.data.perfiles ?? [], permisos: [] };
    modulosResponse.data.forEach((element: any)=>{
      const permiso = usuarioResponse.data.permisos.find((p : PermisoResponse) => p.idModulo === element.id)
      if (permiso){
        usuario.permisos.push({ idModulo: permiso.idModulo, visualizar: permiso.visualizar, registrar: permiso.registrar, modificar: permiso.modificar, eliminar: permiso.eliminar })
      } else {
        usuario.permisos.push({idModulo: element.id, visualizar: false, registrar: false, modificar: false, eliminar: false})
      }
    })
    yield put(layoutApiResponseSuccess(LayoutActionTypes.OPEN_UPDATE_USUARIO_MODAL, { modulos: modulosResponse.data, usuario, mode: request.payload.mode }))
  } catch (error: any) {
    yield put(layoutApiResponseError(LayoutActionTypes.OPEN_UPDATE_USUARIO_MODAL, error));
  }
}


/**
 * Obtiene un usuario
 */
 function* openRegisterMateriaPrimaModal(): SagaIterator {
  try {
      const tiposResponse = yield call(MateriaPrima.getTipos)
      yield put(materiaPrimaApiResponseSuccess(MateriaPrimaActionTypes.GET_TIPOS_MATERIA_PRIMA, tiposResponse.data));
      yield put(layoutApiResponseSuccess(LayoutActionTypes.OPEN_REGISTER_MATERIA_PRIMA_MODAL, {}))
    } catch (error: any) {
      yield put(layoutApiResponseError(LayoutActionTypes.OPEN_REGISTER_MATERIA_PRIMA_MODAL, error));
    }
}

/**
 * Obtiene un usuario
 */
 function* openUpdateMateriaPrimaModal(request : GetByIdData): SagaIterator {
  try {
    const materiaPrimaResponse = yield call(MateriaPrima.getById, {id : request.payload.id});
    const tiposResponse = yield call(MateriaPrima.getTipos);
    const categoriasResponse = yield call(MateriaPrima.getCategoriasByIdTipo, {idTipoMateriaPrima: materiaPrimaResponse.data.idTipoMateriaPrima});
    yield put(materiaPrimaApiResponseSuccess(MateriaPrimaActionTypes.GET_TIPOS_MATERIA_PRIMA, tiposResponse.data));
    yield put(materiaPrimaApiResponseSuccess(MateriaPrimaActionTypes.GET_CATEGORIAS_MATERIA_PRIMA, categoriasResponse.data));
    const subCategoriasResponse = yield call(MateriaPrima.getSubCategoriasByIdCategoria, {idCategoriaMateriaPrima: materiaPrimaResponse.data.idCategoriaMateriaPrima});
    yield put(materiaPrimaApiResponseSuccess(MateriaPrimaActionTypes.GET_SUB_CATEGORIAS_MATERIA_PRIMA, subCategoriasResponse.data));
    yield put(layoutApiResponseSuccess(LayoutActionTypes.OPEN_UPDATE_MATERIA_PRIMA_MODAL, materiaPrimaResponse.data));
  } catch (error: any) {
    yield put(layoutApiResponseError(LayoutActionTypes.OPEN_UPDATE_MATERIA_PRIMA_MODAL, error));
  }
}

/**
 * ---------------------------------------------------------------------------------------------------------------------------
 * Note: Following are the functions which allows you to save the user prefrences on backend side by making an api request.
 * For now, we are just applying the required logic on frontend side
 * ----------------------------------------------------------------------------------------------------------------------------
 */

/**
 * Show the rightsidebar
 */
function* showRightSidebar() {
  try {
    yield call(manageHtmlClass, "right-bar-enabled", "add");
  } catch (error) {}
}

/**
 * Hides the rightsidebar
 */
function* hideRightSidebar() {
  try {
    yield call(manageHtmlClass, "right-bar-enabled", "remove");
  } catch (error) {}
}

export function* watchShowRightSidebar(): any {
  yield takeEvery(LayoutActionTypes.SHOW_RIGHT_SIDEBAR, showRightSidebar);
}

export function* watchHideRightSidebar(): any {
  yield takeEvery(LayoutActionTypes.HIDE_RIGHT_SIDEBAR, hideRightSidebar);
}

export function* watchOpenRegisterUsuarioModal() {
  yield takeEvery(LayoutActionTypes.OPEN_REGISTER_USUARIO_MODAL, openRegisterUsuarioModal);
}

export function* watchOpenUpdateUsuarioModal() {
  yield takeEvery(LayoutActionTypes.OPEN_UPDATE_USUARIO_MODAL, openUpdateUsuarioModal);
}

export function* watchOpenRegisterMateriaPrimaModal() {
  yield takeEvery(LayoutActionTypes.OPEN_REGISTER_MATERIA_PRIMA_MODAL, openRegisterMateriaPrimaModal);
}

export function* watchOpenUpdateMateriaPrimaModal() {
  yield takeEvery(LayoutActionTypes.OPEN_UPDATE_MATERIA_PRIMA_MODAL, openUpdateMateriaPrimaModal);
}

function* LayoutSaga(): any {
  yield all([fork(watchShowRightSidebar), fork(watchHideRightSidebar), fork(watchOpenRegisterUsuarioModal),fork(watchOpenUpdateUsuarioModal),fork(watchOpenRegisterMateriaPrimaModal),fork(watchOpenUpdateMateriaPrimaModal)]);
}

export default LayoutSaga;
