export enum ProductoActionTypes {
    API_RESPONSE_SUCCESS = "@@materiaPrima/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR = "@@materiaPrima/API_RESPONSE_ERROR",

    GET_PAGINATE_PRODUCTO = "@@producto/GET_PAGINATE_PRODUCTO",
    GET_CATEGORIAS_PRODUCTO = "@@producto/GET_CATEGORIAS_PRODUCTO",
    GET_TIPOS_DESCUENTO_PRODUCTO = "@@producto/GET_TIPOS_DESCUENTO_PRODUCTO",
    GET_UNIDADES_PRODUCTO = "@@producto/GET_CATEGORIAS_PRODUCTO",
    INSERT_PRODUCTO = "@@producto/INSERT_PRODUCTO",
    UPDATE_PRODUCTO = "@@producto/UPDATE_PRODUCTO",
    DELETE_PRODUCTO = "@@producto/DELETE_PRODUCTO",
  }