import { SagaIterator } from "redux-saga";
import { MateriaPrima, Producto } from "../../helpers/api";
import { GetPaginateProductoRequest } from "../../requests/producto/GetPaginateProductoRequest";
import { InsertProductoRequest } from "../../requests/producto/InsertProductoRequest";
import { UpdateProductoRequest } from "../../requests/producto/UpdateProductoRequest";
import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { productoApiResponseError, productoApiResponseSuccess } from "./actions";
import { ProductoActionTypes } from "./constants";
import { closeMensajeConfirmacionModal, openMensajeModal, toggleSpinner } from "../actions";
import { TipoMensaje } from "../../components/modals/MensajeModal";

interface GetPaginateData {
    payload: GetPaginateProductoRequest;
    type: string;
}

interface InsertData {
    payload: InsertProductoRequest
    type: string;
}

interface UpdateData {
    payload: UpdateProductoRequest
    type: string;
}

interface DeleteData {
  payload: { id: number }
  type: string;
}

/**
 * Lista las materias primas
 * @param {*} payload
 */
function* getPaginate(request: GetPaginateData): SagaIterator {
    try {
        const response = yield call(Producto.getAllPaginate, request.payload);
        yield put(productoApiResponseSuccess(ProductoActionTypes.GET_PAGINATE_PRODUCTO, response.data));
    } catch (error: any) {
        yield put(productoApiResponseError(ProductoActionTypes.GET_PAGINATE_PRODUCTO, error));
    }
}

function* getUnidades(): SagaIterator {
  try {
    const response = yield call(MateriaPrima.getColores)
    yield put(productoApiResponseSuccess(ProductoActionTypes.GET_UNIDADES_PRODUCTO, response.data));
  } catch (error: any) {
      yield put(productoApiResponseError(ProductoActionTypes.GET_UNIDADES_PRODUCTO, error));
  }
}

function* getTiposDescuentoProducto(): SagaIterator {
  try {
    const response = yield call(MateriaPrima.getTipos)
    yield put(productoApiResponseSuccess(ProductoActionTypes.GET_TIPOS_DESCUENTO_PRODUCTO, response.data));
  } catch (error: any) {
      yield put(productoApiResponseError(ProductoActionTypes.GET_TIPOS_DESCUENTO_PRODUCTO, error));
  }
}

function* getCategorias(request: any): SagaIterator {
  try {
    const response = yield call(MateriaPrima.getCategoriasByIdTipo, request.payload)
    yield put(productoApiResponseSuccess(ProductoActionTypes.GET_CATEGORIAS_PRODUCTO, response.data));
  } catch (error: any) {
      yield put(productoApiResponseError(ProductoActionTypes.GET_CATEGORIAS_PRODUCTO, error));
  }
}

  /**
 * Registra un usuario
 */
 function* insert({ payload, type }: InsertData): SagaIterator {
    try {
      yield put(toggleSpinner(true));
      const response = yield call(Producto.insert, payload);
      yield put(openMensajeModal(TipoMensaje.success, response.data.message))
    } catch (error: any) {
      yield put(openMensajeModal(TipoMensaje.danger, error))
    } finally {
      yield put(toggleSpinner(false));
    }
  }
  
  /**
   * Registra un usuario
   */
   function* update({ payload, type }: UpdateData): SagaIterator {
    try {
      yield put(toggleSpinner(true));
      const response = yield call(Producto.update, payload);
      yield put(openMensajeModal(TipoMensaje.success, response.data.message))
    } catch (error: any) {
      yield put(openMensajeModal(TipoMensaje.danger, error))
    } finally {
      yield put(toggleSpinner(false));
    }
  }

  /**
 * Registra un usuario
 */
 function* deleteProducto({ payload, type }: DeleteData): SagaIterator {
  try {
    yield put(closeMensajeConfirmacionModal());
    yield put(toggleSpinner(true));
    const response = yield call(MateriaPrima.remove, payload);
    yield put(openMensajeModal(TipoMensaje.success, response.data.message))
  } catch (error: any) {
    yield put(openMensajeModal(TipoMensaje.danger, error))
  } finally {
    yield put(toggleSpinner(false));
  }
}

  export function* watchGetPaginateProducto() {
    yield takeEvery(ProductoActionTypes.GET_PAGINATE_PRODUCTO, getPaginate);
  }

  export function* watchGetUnidadesProducto() {
    yield takeEvery(ProductoActionTypes.GET_UNIDADES_PRODUCTO, getUnidades);
  }

  export function* watchGetTiposDescuentoProducto() {
    yield takeEvery(ProductoActionTypes.GET_TIPOS_DESCUENTO_PRODUCTO, getTiposDescuentoProducto);
  }

  export function* watchGetCategoriasProducto() {
    yield takeEvery(ProductoActionTypes.GET_CATEGORIAS_PRODUCTO, getCategorias);
  }

  export function* watchInsertMateriaPrima() {
    yield takeEvery(ProductoActionTypes.INSERT_PRODUCTO, insert);
  }
  
  export function* watchUpdateMateriaPrima() {
    yield takeEvery(ProductoActionTypes.UPDATE_PRODUCTO, update);
  }

  export function* watchDeleteMateriaPrima() {
    yield takeEvery(ProductoActionTypes.DELETE_PRODUCTO, deleteProducto);
  }

  function* productoSaga() {
    yield all([
      fork(watchGetPaginateProducto),fork(watchInsertMateriaPrima),fork(watchUpdateMateriaPrima),fork(watchDeleteMateriaPrima),fork(watchGetUnidadesProducto),fork(watchGetTiposDescuentoProducto),fork(watchGetCategoriasProducto)
    ]);
  }
  
  export default productoSaga;