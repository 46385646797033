// constants
import { TipoMensaje } from "../../components/modals/MensajeModal";
import { MateriaPrimaModalMode } from "../../modulos/materia-prima/MateriaPrimaModal";
import { UsuarioModalMode } from "../../modulos/usuario/UsuarioModal";
import { LayoutActionTypes } from "./constants";

export interface LayoutActionType {
  type:
  | LayoutActionTypes.CHANGE_LAYOUT_MODE
  | LayoutActionTypes.CHANGE_TWOCOLUMN_THEME
  | LayoutActionTypes.CHANGE_LAYOUT
  | LayoutActionTypes.CHANGE_LAYOUT_COLOR
  | LayoutActionTypes.CHANGE_LAYOUT_WIDTH
  | LayoutActionTypes.CHANGE_MENU_POSITIONS
  | LayoutActionTypes.CHANGE_SIDEBAR_THEME
  | LayoutActionTypes.CHANGE_SIDEBAR_TYPE
  | LayoutActionTypes.TOGGLE_SIDEBAR_USER_INFO
  | LayoutActionTypes.CHANGE_TOPBAR_THEME
  | LayoutActionTypes.TOGGLE_TWO_TONE_ICONS
  | LayoutActionTypes.SHOW_RIGHT_SIDEBAR
  | LayoutActionTypes.TOGGLE_SPINNER
  | LayoutActionTypes.HIDE_RIGHT_SIDEBAR
  | LayoutActionTypes.API_RESPONSE_SUCCESS
  | LayoutActionTypes.API_RESPONSE_ERROR
  | LayoutActionTypes.OPEN_MENSAJE_MODAL
  | LayoutActionTypes.CLOSE_MENSAJE_MODAL
  | LayoutActionTypes.OPEN_REGISTER_USUARIO_MODAL
  | LayoutActionTypes.OPEN_UPDATE_USUARIO_MODAL
  | LayoutActionTypes.CLOSE_USUARIO_MODAL
  | LayoutActionTypes.OPEN_REGISTER_MATERIA_PRIMA_MODAL
  | LayoutActionTypes.OPEN_UPDATE_MATERIA_PRIMA_MODAL
  | LayoutActionTypes.CLOSE_MATERIA_PRIMA_MODAL
  | LayoutActionTypes.OPEN_MENSAJE_CONFIRMACION_MODAL
  | LayoutActionTypes.CLOSE_MENSAJE_CONFIRMACION_MODAL
  payload: {} | string;
}

// common success
export const layoutApiResponseSuccess = (
  actionType: string,
  data: {}
): LayoutActionType => ({
  type: LayoutActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const layoutApiResponseError = (
  actionType: string,
  error: string
): LayoutActionType => ({
  type: LayoutActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const changeLayoutModes = (mode: string): LayoutActionType => ({
  type: LayoutActionTypes.CHANGE_LAYOUT_MODE,
  payload: mode,
});

export const changeTwoColumnThemes = (
  mode: string
): LayoutActionType => {
  return {
    type: LayoutActionTypes.CHANGE_TWOCOLUMN_THEME,
    payload: mode,
  };
};

export const changeLayout = (layout: string): LayoutActionType => ({
  type: LayoutActionTypes.CHANGE_LAYOUT,
  payload: layout,
});

export const changeLayoutColor = (color: string): LayoutActionType => ({
  type: LayoutActionTypes.CHANGE_LAYOUT_COLOR,
  payload: color,
});

export const changeLayoutWidth = (width: string): LayoutActionType => ({
  type: LayoutActionTypes.CHANGE_LAYOUT_WIDTH,
  payload: width,
});

export const changeMenuPositions = (position: string): LayoutActionType => ({
  type: LayoutActionTypes.CHANGE_MENU_POSITIONS,
  payload: position,
});

export const changeSidebarTheme = (sidebarTheme: string): LayoutActionType => ({
  type: LayoutActionTypes.CHANGE_SIDEBAR_THEME,
  payload: sidebarTheme,
});

export const changeSidebarType = (sidebarType: string): LayoutActionType => {
  return ({
  type: LayoutActionTypes.CHANGE_SIDEBAR_TYPE,
  payload: sidebarType,
})};

export const toggleSidebarUserInfo = (showSidebarUserInfo: boolean): LayoutActionType => ({
  type: LayoutActionTypes.TOGGLE_SIDEBAR_USER_INFO,
  payload: showSidebarUserInfo,
});

export const toggleSpinner = (showSpinner: boolean): LayoutActionType => ({
  type: LayoutActionTypes.TOGGLE_SPINNER,
  payload: showSpinner,
});

export const changeTopbarTheme = (topbarTheme: string): LayoutActionType => ({
  type: LayoutActionTypes.CHANGE_TOPBAR_THEME,
  payload: topbarTheme,
});

export const toggleTwoToneIcons = (showTwoToneIcons: boolean): LayoutActionType => ({
  type: LayoutActionTypes.TOGGLE_TWO_TONE_ICONS,
  payload: showTwoToneIcons,
});

export const showRightSidebar = (): LayoutActionType => ({
  type: LayoutActionTypes.SHOW_RIGHT_SIDEBAR,
  payload: {}
});

export const hideRightSidebar = (): LayoutActionType => ({
  type: LayoutActionTypes.HIDE_RIGHT_SIDEBAR,
  payload: {}
});

export const openMensajeModal = ( tipo: TipoMensaje, mensaje: string ) : LayoutActionType => ({
  type: LayoutActionTypes.OPEN_MENSAJE_MODAL,
  payload: { tipo, mensaje }
})

export const closeMensajeModal = ( ) : LayoutActionType => ({
  type: LayoutActionTypes.CLOSE_MENSAJE_MODAL,
  payload: { }
})

export const openMensajeConfirmacionModal = (confirmar: () => void) : LayoutActionType => ({
  type: LayoutActionTypes.OPEN_MENSAJE_CONFIRMACION_MODAL,
  payload: { confirmar }
})

export const closeMensajeConfirmacionModal = ( ) : LayoutActionType => ({
  type: LayoutActionTypes.CLOSE_MENSAJE_CONFIRMACION_MODAL,
  payload: { }
})

export const closeUsuarioModal = ( ) : LayoutActionType => ({
  type: LayoutActionTypes.CLOSE_USUARIO_MODAL,
  payload: { }
})

export const openRegisterUsuarioModal = ( ) : LayoutActionType => ({
  type: LayoutActionTypes.OPEN_REGISTER_USUARIO_MODAL,
  payload: { }
})

export const openUpdateUsuarioModal = ( id: number, mode: UsuarioModalMode ) : LayoutActionType => ({
  type: LayoutActionTypes.OPEN_UPDATE_USUARIO_MODAL,
  payload: { id, mode }
})

export const closeMateriaPrimaModal = ( ) : LayoutActionType => ({
  type: LayoutActionTypes.CLOSE_MATERIA_PRIMA_MODAL,
  payload: { }
})

export const openRegisterMateriaPrimaModal = ( ) : LayoutActionType => ({
  type: LayoutActionTypes.OPEN_REGISTER_MATERIA_PRIMA_MODAL,
  payload: { }
})

export const openUpdateMateriaPrimaModal = (id : number, mode: MateriaPrimaModalMode) : LayoutActionType => ({
  type: LayoutActionTypes.OPEN_UPDATE_MATERIA_PRIMA_MODAL,
  payload: { id, mode }
})