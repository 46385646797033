import React, { useCallback } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { closeMensajeModal as closeMensajeModalAction, closeUsuarioModal as closeUsuarioModalAction, closeMateriaPrimaModal as closeMateriaPrimaModalAction, closeMensajeConfirmacionModal as closeMensajeConfirmacionModalAction } from "../redux/actions";
import { FloatingWhatsApp } from 'react-floating-whatsapp'

// layout constants
import { LayoutTypes } from "../constants/layout";

// strore
import { AppDispatch, RootState } from "../redux/store";

// All layouts containers
import DefaultLayout from "../layouts/Default";
import VerticalLayout from "../layouts/Vertical";
import DetachedLayout from "../layouts/Detached";
import HorizontalLayout from "../layouts/Horizontal/";
import TwoColumnLayout from "../layouts/TwoColumn/";

import {
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
} from "./index";
import { APICore } from "../helpers/api/apiCore";
import UsuarioModal from "../modulos/usuario/UsuarioModal";
import { MensajeModal } from "../components/modals/MensajeModal";
import MateriaPrimaModal from "../modulos/materia-prima/MateriaPrimaModal";
import { MensajeConfirmacionModal } from "../components/modals/MensajeConfirmacionModal";

interface IRoutesProps { }

const AllRoutes = (props: IRoutesProps) => {
  const { layout, usuario, materiaPrima } = useSelector((state: RootState) => ({
    layout: state.Layout,
    usuario: state.Usuario,
    materiaPrima: state.MateriaPrima
  }));
  const dispatch = useDispatch<AppDispatch>();
  const getLayout = () => {
    let layoutCls = TwoColumnLayout;

    switch (layout.layoutType) {
      case LayoutTypes.LAYOUT_HORIZONTAL:
        layoutCls = HorizontalLayout;
        break;
      case LayoutTypes.LAYOUT_DETACHED:
        layoutCls = DetachedLayout;
        break;
      case LayoutTypes.LAYOUT_VERTICAL:
        layoutCls = VerticalLayout;
        break;
      default:
        layoutCls = TwoColumnLayout;
        break;
    }
    return layoutCls;
  };

  const closeUsuarioModal = useCallback(()=>{
    dispatch(closeUsuarioModalAction());
  },[dispatch])

  const closeMateriaPrimaModal = useCallback(()=>{
    dispatch(closeMateriaPrimaModalAction());
  },[dispatch])

  const closeMensajeModal = useCallback(()=>{
    dispatch(closeMensajeModalAction());
  },[dispatch])

  const closeMensajeConfirmacionModal = useCallback(()=>{
    dispatch(closeMensajeConfirmacionModalAction());
  },[dispatch])

  let Layout = getLayout();
  const api = new APICore();

  return (
    <React.Fragment>
      <Routes>
        <Route>
          {publicProtectedFlattenRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <DefaultLayout {...props} layout={layout}>
                  {route.element}
                </DefaultLayout>
              }
              key={idx}
            />
          ))}
        </Route>

        <Route>
          {authProtectedFlattenRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                api.isUserAuthenticated() === false ? (
                  <Navigate
                    to={{
                      pathname: "/auth/login",
                      // hash:route.path,
                      search: "next=" + route.path,
                    }}
                  />
                ) : (
                  <React.Fragment>
                    <Layout {...props}>{route.element}</Layout>
                    <FloatingWhatsApp allowClickAway phoneNumber="51904459829" accountName="De La Sur" chatMessage="Hola, ¿Cómo podemos ayudarte?" statusMessage="Normalmente responde en menos de una hora"/>
                    {layout.usuarioModal.show && <UsuarioModal show={layout.usuarioModal.show} toggle={closeUsuarioModal} usuario={layout.usuarioModal.usuario} mode={layout.usuarioModal.mode} modulos={layout.usuarioModal.modulos} perfiles={usuario.perfiles}></UsuarioModal>}
                    {layout.materiaPrimaModal.show && <MateriaPrimaModal colores={materiaPrima.colores} tipos={materiaPrima.tipos} categorias={materiaPrima.categorias} subCategorias={materiaPrima.subCategorias} show={layout.materiaPrimaModal.show} toggle={closeMateriaPrimaModal} materiaPrima={layout.materiaPrimaModal.materiaPrima} mode={layout.materiaPrimaModal.mode}></MateriaPrimaModal>}
                    {layout.mensajeModal.show && <MensajeModal show={layout.mensajeModal.show} tipoMensaje={layout.mensajeModal.tipo} toggle={closeMensajeModal} mensaje={layout.mensajeModal.mensaje}></MensajeModal>}
                    {layout.mensajeConfirmacionModal.show && <MensajeConfirmacionModal show={layout.mensajeConfirmacionModal.show} confirmar={layout.mensajeConfirmacionModal.confirmar} toggle={closeMensajeConfirmacionModal}></MensajeConfirmacionModal>}
                  </React.Fragment>
                )
              }
              key={idx}
            />
          ))}
        </Route>
      </Routes>
    </React.Fragment>
  );
};

export default AllRoutes;
