// apicore
import { BaseResponse } from "../../common/BaseResponse";
import { PaginateResponse } from "../../common/PaginateResponse";
import { TipoMensaje } from "../../components/modals/MensajeModal";
import { ProductoResponse } from "../../responses/producto/ProductoResponse";
import { ProductosResponse } from "../../responses/producto/ProductosResponse";
import { GetPaginateProductoRequest } from "../../requests/producto/GetPaginateProductoRequest";

// constants
import { ProductoActionTypes } from "./constants";

const INIT_STATE = {
  getPaginateProductoRequest: { page: 1, pageSize: 10, orderType: false, orderBy: '', desde: '', hasta: '', nombre: '', descripcion: '', categorias: '', disponible: true },
  productos: { results: [], totalRows: 0 },
  producto: { id: 0, nombre: '', descripcion: '', idCategoriaProducto: 0, idUnidad: 0, idTipoDescuento: 0, descuento: 0, disponible: false, medidas: [], materiasPrimas: [], espacios: [] },
  materiasPrimasCombo: [],
  loadingTarifas: false,
  tiposDescuento: [],
  unidades: [],
  categorias: [],
  subCategorias: [],
  showProductoModal: false,
  showMensaje: false,
  loadingProductos: false,
  loadingColores: false,
  loadingTipos: false,
  loadingCategorias: false,
  loadingSubCategorias: false,
  loadingModal: false,
  loadingInsert: false,
  loadingUpdate: false
};

interface UsuarioActionType {
  type:
  | ProductoActionTypes.API_RESPONSE_SUCCESS
  | ProductoActionTypes.API_RESPONSE_ERROR
  | ProductoActionTypes.GET_PAGINATE_PRODUCTO
  | ProductoActionTypes.INSERT_PRODUCTO
  | ProductoActionTypes.UPDATE_PRODUCTO
  payload: {
    actionType?: string;
    data: any;
    error?: any;
  };
}

interface State {
  getPaginateProductoRequest: GetPaginateProductoRequest;
  productos: PaginateResponse<ProductosResponse> | {};
  materiasPrimasCombo: BaseResponse[];
  loadingTarifas: boolean;
  producto: ProductoResponse | {};
  tiposDescuento: any[];
  unidades: any[];
  categorias: any[];
  loadingProductos: boolean;
  loadingColores: boolean;
  loadingTipos: boolean;
  loadingCategorias: boolean;
  loadingSubCategorias: boolean;
  loadingModal: boolean;
  loadingInsert: boolean;
  loadingUpdate: boolean;
  showProductoModal: boolean;
  showMensaje: boolean;
}

const Producto = (state: State = INIT_STATE, action: UsuarioActionType): any => {
  switch (action.type) {
    case ProductoActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case ProductoActionTypes.GET_PAGINATE_PRODUCTO: {
          return {
            ...state,
            productos: action.payload.data,
            loadingProductos: false,
          };
        }
        case ProductoActionTypes.GET_TIPOS_DESCUENTO_PRODUCTO: {
          return {
            ...state,
            tiposDescuento: action.payload.data,
            loadingTipos: false,
          };
        }
        case ProductoActionTypes.GET_CATEGORIAS_PRODUCTO: {
          return {
            ...state,
            categorias: action.payload.data,
            loadingCategorias: false,
          };
        }
        case ProductoActionTypes.GET_UNIDADES_PRODUCTO: {
          return {
            ...state,
            subCategorias: action.payload.data,
            loadingSubCategorias: false,
          };
        }
        case ProductoActionTypes.INSERT_PRODUCTO: {
          return {
            ...state,
            producto: INIT_STATE.producto,
            showProductoModal: false,
            showMensaje: true,
            loadingInsert: false,
            loadingModal: false,
            mensaje: action.payload.data.message,
            tipoMensaje: TipoMensaje.success
          };
        }
        case ProductoActionTypes.UPDATE_PRODUCTO: {
          return {
            ...state,
            producto: INIT_STATE.producto,
            showProductoModal: false,
            loadingUpdate: false,
            mensaje: action.payload.data.message
          };
        }
        default:
          return { ...state };
      }

    case ProductoActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case ProductoActionTypes.GET_PAGINATE_PRODUCTO: {
          return {
            ...state,
            error: action.payload.error,
            loadingProductos: false,
          };
        }
        case ProductoActionTypes.INSERT_PRODUCTO: {
          return {
            ...state,
            loadingInsert: false,
            loadingModal: false,
            showMensaje: true,
            mensaje: action.payload.error,
            tipoMensaje: TipoMensaje.danger
          };
        }
        case ProductoActionTypes.UPDATE_PRODUCTO: {
          return {
            ...state,
            loadingUpdate: false,
            mensaje: action.payload.error,
          };
        }
        default:
          return { ...state };
      }
    case ProductoActionTypes.GET_PAGINATE_PRODUCTO:
      return { ...state, loadingProductos: true, getPaginateProductoRequest: action.payload };
    case ProductoActionTypes.INSERT_PRODUCTO:
      return { ...state, loadingInsert: true, loadingModal: true, };
    case ProductoActionTypes.UPDATE_PRODUCTO:
      return { ...state, loadingUpdate: true };
    default:
      return { ...state };
  }
};

export default Producto;