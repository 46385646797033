import { GetAllPaginateUsuarioRequest } from "../../requests/usuario/GetAllPaginateUsuarioRequest";
import { InsertUsuarioRequest } from "../../requests/usuario/InsertUsuarioRequest";
import { UpdateUsuarioRequest } from "../../requests/usuario/UpdateUsuarioRequest";
import { APICore } from "./apiCore";

const api = new APICore();
  // account
  export function loginDashboard(params: { correo: string; password: string }) {
    const baseUrl = "usuario/logindashboard";
    return api.create(`${baseUrl}`, params);
  }

  export function getPaginate(params: GetAllPaginateUsuarioRequest) {
    const baseUrl = "usuario/paginate";
    return api.get(`${baseUrl}`, params);
  }

  export function getById(params: { id: number }) {
    const baseUrl = "usuario/";
    return api.get(`${baseUrl}${params.id}`, null);
  }

  export function getModulos() {
    const baseUrl = "usuario/modulo";
    return api.get(`${baseUrl}`, null);
  }
  
  export function getPerfiles() {
    const baseUrl = "usuario/perfil";
    return api.get(`${baseUrl}`, null);
  }

  export function insert(params: InsertUsuarioRequest){
    const baseUrl = "usuario";
    return api.createWithFile(`${baseUrl}`, params)
  }

  export function update(params: UpdateUsuarioRequest){
    const baseUrl = "usuario";
    return api.updateWithFile(`${baseUrl}/${params.id}`, params)
  }

  export function remove(params: {id: number}){
    const baseUrl = "usuario";
    return api.delete(`${baseUrl}/${params.id}`)
  }
