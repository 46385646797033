// apicore
import { BaseResponse } from "../../common/BaseResponse";
import { PaginateResponse } from "../../common/PaginateResponse";
import { TipoMensaje } from "../../components/modals/MensajeModal";
import { APICore } from "../../helpers/api/apiCore";
import { UsuarioResponse } from "../../responses/usuario/UsuarioResponse";
import { UsuariosResponse } from "../../responses/usuario/UsuariosResponse";

// constants
import { UsuarioActionTypes } from "./constants";

const api = new APICore();

const INIT_STATE = {
  token: api.getLoggedInUser(),
  usuarios: { results: [], totalRows: 0 },
  usuario: { id: 0, nombres: '', apellidos: '', correo: '', celular: '', dni: '', foto: '', perfiles: [], permisos: [] },
  modulos: [],
  perfiles: [],
  showUsuarioModal: false,
  showMensaje: false,
  loading: false,
  loadingUsuarios: false,
  loadingPerfiles: false,
  loadingInsert: false,
  loadingUpdate: false,
  mensaje: "",
  tipoMensaje: "",
};

interface UsuarioActionType {
  type:
  | UsuarioActionTypes.API_RESPONSE_SUCCESS
  | UsuarioActionTypes.API_RESPONSE_ERROR
  | UsuarioActionTypes.LOGIN_DASHBOARD_USUARIO
  | UsuarioActionTypes.GET_PAGINATE_USUARIO
  | UsuarioActionTypes.GET_PERFILES_USUARIO
  | UsuarioActionTypes.INSERT_USUARIO
  | UsuarioActionTypes.UPDATE_USUARIO
  | UsuarioActionTypes.RESET;
  payload: {
    actionType?: string;
    data: any;
    error?: any;
  };
}

interface State {
  token: string;
  usuarios: PaginateResponse<UsuariosResponse> | {};
  usuario: UsuarioResponse | {};
  modulos: BaseResponse[];
  perfiles: BaseResponse[];
  loadingUsuarios: boolean;
  loadingPerfiles: boolean;
  loadingInsert: boolean;
  loadingUpdate: boolean;
  showUsuarioModal: boolean;
  showMensaje: boolean;
  loading?: boolean;
  value?: boolean;
  mensaje: string;
  tipoMensaje: string;
}

const Usuario = (state: State = INIT_STATE, action: UsuarioActionType): any => {
  switch (action.type) {
    case UsuarioActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case UsuarioActionTypes.LOGIN_DASHBOARD_USUARIO: {
          return {
            ...state,
            user: action.payload.data,
            userLoggedIn: true,
            loading: false,
          };
        }
        case UsuarioActionTypes.GET_PAGINATE_USUARIO: {
          return {
            ...state,
            usuarios: action.payload.data,
            loadingUsuarios: false,
          };
        }
        case UsuarioActionTypes.GET_PERFILES_USUARIO: {
          return {
            ...state,
            perfiles: action.payload.data,
            loadingPerfiles: false,
          };
        }
        case UsuarioActionTypes.INSERT_USUARIO: {
          return {
            ...state,
            usuario: INIT_STATE.usuario,
            showUsuarioModal: false,
            showMensaje: true,
            loadingInsert: false,
            loadingModal: false,
            mensaje: action.payload.data.message,
            tipoMensaje: TipoMensaje.success
          };
        }
        case UsuarioActionTypes.UPDATE_USUARIO: {
          return {
            ...state,
            usuario: INIT_STATE.usuario,
            showUsuarioModal: false,
            loadingUpdate: false,
            mensaje: action.payload.data.message
          };
        }
        default:
          return { ...state };
      }

    case UsuarioActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case UsuarioActionTypes.LOGIN_DASHBOARD_USUARIO: {
          return {
            ...state,
            error: action.payload.error,
            userLoggedIn: false,
            loading: false,
          };
        }
        case UsuarioActionTypes.GET_PAGINATE_USUARIO: {
          return {
            ...state,
            error: action.payload.error,
            loadingUsuarios: false,
          };
        }
        case UsuarioActionTypes.GET_PERFILES_USUARIO: {
          return {
            ...state,
            error: action.payload.error,
            loadingPerfiles: false,
          };
        }
        case UsuarioActionTypes.INSERT_USUARIO: {
          return {
            ...state,
            loadingInsert: false,
            loadingModal: false,
            showMensaje: true,
            mensaje: action.payload.error,
            tipoMensaje: TipoMensaje.danger
          };
        }
        case UsuarioActionTypes.UPDATE_USUARIO: {
          return {
            ...state,
            loadingUpdate: false,
            mensaje: action.payload.error,
          };
        }
        default:
          return { ...state };
      }
    case UsuarioActionTypes.LOGIN_DASHBOARD_USUARIO:
      return { ...state, loading: true, userLoggedIn: false };
    case UsuarioActionTypes.GET_PAGINATE_USUARIO:
      return { ...state, loadingUsuarios: true };
    case UsuarioActionTypes.GET_PERFILES_USUARIO:
      return { ...state, loadingPerfiles: true };
    case UsuarioActionTypes.INSERT_USUARIO:
      return { ...state, loadingInsert: true, loadingModal: true, };
    case UsuarioActionTypes.UPDATE_USUARIO:
      return { ...state, loadingUpdate: true };
    case UsuarioActionTypes.RESET:
      return {
        ...state,
        loading: false,
        error: false,
        userSignUp: false,
        userLoggedIn: false,
        passwordReset: false,
        passwordChange: false,
        resetPasswordSuccess: null,
      };
    default:
      return { ...state };
  }
};

export default Usuario;
