import { SagaIterator } from "redux-saga";
import { MateriaPrima } from "../../helpers";
import { GetPaginateMateriaPrimaRequest } from "../../requests/materiaPrima/GetPaginateMateriaPrimaRequest";
import { MateriaPrimaActionTypes } from "./constants";
import { all, fork, put, takeEvery, call } from "redux-saga/effects";
// actions
import { materiaPrimaApiResponseSuccess, materiaPrimaApiResponseError } from "./actions";
import { InsertMateriaPrimaRequest } from "../../requests/materiaPrima/InsertMateriaPrimaRequest";
import { UpdateMateriaPrimaRequest } from "../../requests/materiaPrima/UpdateMateriaPrimaRequest";
import { GetTarifasPaginateMateriaPrimaRequest } from "../../requests/materiaPrima/GetTarifasPaginateMateriaPrimaRequest";
import { closeMensajeConfirmacionModal, openMensajeModal, toggleSpinner } from "../actions";
import { TipoMensaje } from "../../components/modals/MensajeModal";

interface GetAllPaginateData {
    payload: GetPaginateMateriaPrimaRequest;
    type: string;
}


interface GetTarifasPaginateData {
  payload: GetTarifasPaginateMateriaPrimaRequest;
  type: string;
}

interface InsertData {
    payload: InsertMateriaPrimaRequest
    type: string;
}

interface UpdateData {
    payload: UpdateMateriaPrimaRequest
    type: string;
}

interface DeleteData {
  payload: { id: number }
  type: string;
}

/**
 * Lista las materias primas
 * @param {*} payload
 */
function* getPaginate(request: GetAllPaginateData): SagaIterator {
    try {
        const response = yield call(MateriaPrima.getPaginate, request.payload);
        yield put(materiaPrimaApiResponseSuccess(MateriaPrimaActionTypes.GET_PAGINATE_MATERIA_PRIMA, response.data));
    } catch (error: any) {
        yield put(materiaPrimaApiResponseError(MateriaPrimaActionTypes.GET_PAGINATE_MATERIA_PRIMA, error));
    }
}

function* getTarifasPaginate(request: GetTarifasPaginateData): SagaIterator {
  try {
      const response = yield call(MateriaPrima.getTarifasPaginate, request.payload);
      yield put(materiaPrimaApiResponseSuccess(MateriaPrimaActionTypes.GET_TARIFAS_PAGINATE_MATERIA_PRIMA, response.data));
  } catch (error: any) {
      yield put(materiaPrimaApiResponseError(MateriaPrimaActionTypes.GET_TARIFAS_PAGINATE_MATERIA_PRIMA, error));
  }
}

function* getColores(): SagaIterator {
  try {
    const response = yield call(MateriaPrima.getColores)
    yield put(materiaPrimaApiResponseSuccess(MateriaPrimaActionTypes.GET_COLORES_MATERIA_PRIMA, response.data));
  } catch (error: any) {
      yield put(materiaPrimaApiResponseError(MateriaPrimaActionTypes.GET_COLORES_MATERIA_PRIMA, error));
  }
}

function* getTipos(): SagaIterator {
  try {
    const response = yield call(MateriaPrima.getTipos)
    yield put(materiaPrimaApiResponseSuccess(MateriaPrimaActionTypes.GET_TIPOS_MATERIA_PRIMA, response.data));
  } catch (error: any) {
      yield put(materiaPrimaApiResponseError(MateriaPrimaActionTypes.GET_TIPOS_MATERIA_PRIMA, error));
  }
}

function* getCategoriasByIdTipo(request: any): SagaIterator {
  try {
    const response = yield call(MateriaPrima.getCategoriasByIdTipo, request.payload)
    yield put(materiaPrimaApiResponseSuccess(MateriaPrimaActionTypes.GET_CATEGORIAS_MATERIA_PRIMA, response.data));
  } catch (error: any) {
      yield put(materiaPrimaApiResponseError(MateriaPrimaActionTypes.GET_CATEGORIAS_MATERIA_PRIMA, error));
  }
}

function* getSubCategoriasByIdCategoria(request: any): SagaIterator {
  try {
    const response = yield call(MateriaPrima.getSubCategoriasByIdCategoria, request.payload)
    yield put(materiaPrimaApiResponseSuccess(MateriaPrimaActionTypes.GET_SUB_CATEGORIAS_MATERIA_PRIMA, response.data));
  } catch (error: any) {
      yield put(materiaPrimaApiResponseError(MateriaPrimaActionTypes.GET_SUB_CATEGORIAS_MATERIA_PRIMA, error));
  }
}

  /**
 * Registra un usuario
 */
 function* insert({ payload, type }: InsertData): SagaIterator {
    try {
      yield put(toggleSpinner(true));
      const response = yield call(MateriaPrima.insert, payload);
      yield put(openMensajeModal(TipoMensaje.success, response.data.message))
    } catch (error: any) {
      yield put(openMensajeModal(TipoMensaje.danger, error))
    } finally {
      yield put(toggleSpinner(false));
    }
  }
  
  /**
   * Registra un usuario
   */
   function* update({ payload, type }: UpdateData): SagaIterator {
    try {
      yield put(toggleSpinner(true));
      const response = yield call(MateriaPrima.update, payload);
      yield put(openMensajeModal(TipoMensaje.success, response.data.message))
    } catch (error: any) {
      yield put(openMensajeModal(TipoMensaje.danger, error))
    } finally {
      yield put(toggleSpinner(false));
    }
  }

  /**
 * Registra un usuario
 */
 function* deleteMateriaPrima({ payload, type }: DeleteData): SagaIterator {
  try {
    yield put(closeMensajeConfirmacionModal());
    yield put(toggleSpinner(true));
    const response = yield call(MateriaPrima.remove, payload);
    yield put(openMensajeModal(TipoMensaje.success, response.data.message))
  } catch (error: any) {
    yield put(openMensajeModal(TipoMensaje.danger, error))
  } finally {
    yield put(toggleSpinner(false));
  }
}

  export function* watchGetPaginateMateriaPrima() {
    yield takeEvery(MateriaPrimaActionTypes.GET_PAGINATE_MATERIA_PRIMA, getPaginate);
  }

  export function* watchGetTarifasPaginateMateriaPrima() {
    yield takeEvery(MateriaPrimaActionTypes.GET_TARIFAS_PAGINATE_MATERIA_PRIMA, getTarifasPaginate);
  }

  export function* watchGetColoresMateriaPrima() {
    yield takeEvery(MateriaPrimaActionTypes.GET_COLORES_MATERIA_PRIMA, getColores);
  }

  export function* watchGetTiposMateriaPrima() {
    yield takeEvery(MateriaPrimaActionTypes.GET_TIPOS_MATERIA_PRIMA, getTipos);
  }

  export function* watchGetCategoriasByIdTipoMateriaPrima() {
    yield takeEvery(MateriaPrimaActionTypes.GET_CATEGORIAS_MATERIA_PRIMA, getCategoriasByIdTipo);
  }

  export function* watchGetSubCategoriasByIdCategoriaMateriaPrima() {
    yield takeEvery(MateriaPrimaActionTypes.GET_SUB_CATEGORIAS_MATERIA_PRIMA, getSubCategoriasByIdCategoria);
  }

  export function* watchInsertMateriaPrima() {
    yield takeEvery(MateriaPrimaActionTypes.INSERT_MATERIA_PRIMA, insert);
  }
  
  export function* watchUpdateMateriaPrima() {
    yield takeEvery(MateriaPrimaActionTypes.UPDATE_MATERIA_PRIMA, update);
  }

  export function* watchDeleteMateriaPrima() {
    yield takeEvery(MateriaPrimaActionTypes.DELETE_MATERIA_PRIMA, deleteMateriaPrima);
  }

  function* materiaPrimaSaga() {
    yield all([
      fork(watchGetPaginateMateriaPrima),fork(watchGetTarifasPaginateMateriaPrima),fork(watchInsertMateriaPrima),fork(watchUpdateMateriaPrima),fork(watchDeleteMateriaPrima),fork(watchGetColoresMateriaPrima),fork(watchGetTiposMateriaPrima),fork(watchGetCategoriasByIdTipoMateriaPrima),fork(watchGetSubCategoriasByIdCategoriaMateriaPrima)
    ]);
  }
  
  export default materiaPrimaSaga;
