import { combineReducers } from "redux";

import Usuario from "./usuario/reducers";
import MateriaPrima from "./materiaPrima/reducers";
import Producto from "./producto/reducers";
import Layout from "./layout/reducers";

export default combineReducers({
  Usuario,
  MateriaPrima,
  Layout,
  Producto
});
