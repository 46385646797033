// constants
import { BaseResponse } from "../../common/BaseResponse";
import { TipoMensaje } from "../../components/modals/MensajeModal";
import {
  LayoutMode,
  LayoutTypes,
  LayoutColor,
  LayoutWidth,
  MenuPositions,
  SideBarTheme,
  SideBarTypes,
  TopbarTheme,
  TwoColumnTheme,
} from "../../constants/layout";
import { MateriaPrimaModalMode } from "../../modulos/materia-prima/MateriaPrimaModal";
import { UsuarioModalMode } from "../../modulos/usuario/UsuarioModal";
import { MateriaPrimaResponse } from "../../responses/materiaPrima/MateriaPrimaResponse";
import { UsuarioResponse } from "../../responses/usuario/UsuarioResponse";

enum LayoutActionTypes {
  API_RESPONSE_SUCCESS = "@@layout/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@layout/API_RESPONSE_ERROR",

  CHANGE_TWOCOLUMN_THEME = "@@layout/CHANGE_TWOCOLUMN_THEME",
  CHANGE_LAYOUT_MODE = "@@layout/CHANGE_LAYOUT_MODE",
  CHANGE_LAYOUT = "@@layout/CHANGE_LAYOUT",
  CHANGE_LAYOUT_COLOR = "@@layout/CHANGE_LAYOUT_COLOR",
  CHANGE_LAYOUT_WIDTH = "@@layout/CHANGE_LAYOUT_WIDTH",
  CHANGE_MENU_POSITIONS = "@@layout/CHANGE_MENU_POSITIONS",
  CHANGE_SIDEBAR_THEME = "@@layout/CHANGE_SIDEBAR_THEME",
  CHANGE_SIDEBAR_TYPE = "@@layout/CHANGE_SIDEBAR_TYPE",
  TOGGLE_SIDEBAR_USER_INFO = "@@layout/TOGGLE_SIDEBAR_USER_INFO",
  TOGGLE_TWO_TONE_ICONS = "@@layout/TOGGLE_TWO_TONE_ICONS",
  CHANGE_TOPBAR_THEME = "@@layout/CHANGE_TOPBAR_THEME",
  TOGGLE_SPINNER = "@@layout/TOGGLE_SPINNER",

  OPEN_MENSAJE_MODAL = "@@layout/OPEN_MENSAJE_MODAL",
  CLOSE_MENSAJE_MODAL = "@@layout/CLOSE_MENSAJE_MODAL",

  OPEN_MENSAJE_CONFIRMACION_MODAL = "@@layout/OPEN_MENSAJE_CONFIRMACION_MODAL",
  CLOSE_MENSAJE_CONFIRMACION_MODAL = "@@layout/CLOSE_MENSAJE_CONFIRMACION_MODAL",

  OPEN_REGISTER_USUARIO_MODAL = "@@layout/OPEN_REGISTER_USUARIO_MODAL",
  OPEN_UPDATE_USUARIO_MODAL = "@@layout/OPEN_UPDATE_USUARIO_MODAL",
  CLOSE_USUARIO_MODAL = "@@layout/CLOSE_USUARIO_MODAL",

  OPEN_REGISTER_MATERIA_PRIMA_MODAL = "@@layout/OPEN_REGISTER_MATERIA_PRIMA_MODAL",
  OPEN_UPDATE_MATERIA_PRIMA_MODAL = "@@layout/OPEN_UPDATE_MATERIA_PRIMA_MODAL",
  CLOSE_MATERIA_PRIMA_MODAL = "@@layout/CLOSE_MATERIA_PRIMA_MODAL",

  SHOW_RIGHT_SIDEBAR = "@@layout/SHOW_RIGHT_SIDEBAR",
  HIDE_RIGHT_SIDEBAR = "@@layout/HIDE_RIGHT_SIDEBAR",
}

export interface LayoutStateTypes {
  twoColumnTheme:
    | TwoColumnTheme.TWOCOLUMN_LIGHT
    | TwoColumnTheme.TWOCOLUMN_DARK
    | TwoColumnTheme.TWOCOLUMN_BRAND
    | TwoColumnTheme.TWOCOLUMN_GRADIENT;
  layoutMode: LayoutMode.LAYOUT_DEFAULT | LayoutMode.LAYOUT_DETACHED;
  layoutType:
    | LayoutTypes.LAYOUT_VERTICAL
    | LayoutTypes.LAYOUT_TWO_COLUMN
    | LayoutTypes.LAYOUT_DETACHED
    | LayoutTypes.LAYOUT_HORIZONTAL;
  layoutColor: LayoutColor.LAYOUT_COLOR_LIGHT | LayoutColor.LAYOUT_COLOR_DARK;
  layoutWidth: LayoutWidth.LAYOUT_WIDTH_FLUID | LayoutWidth.LAYOUT_WIDTH_BOXED;
  menuPosition:
    | MenuPositions.MENU_POSITION_FIXED
    | MenuPositions.MENU_POSITION_SCROLLABLE;
  leftSideBarTheme:
    | SideBarTheme.LEFT_SIDEBAR_THEME_LIGHT
    | SideBarTheme.LEFT_SIDEBAR_THEME_DARK
    | SideBarTheme.LEFT_SIDEBAR_THEME_BRAND
    | SideBarTheme.LEFT_SIDEBAR_THEME_GRADIENT;
  leftSideBarType:
    | SideBarTypes.LEFT_SIDEBAR_TYPE_DEFAULT
    | SideBarTypes.LEFT_SIDEBAR_TYPE_CONDENSED
    | SideBarTypes.LEFT_SIDEBAR_TYPE_COMPACT
    | SideBarTypes.LEFT_SIDEBAR_TYPE_FULL
    | SideBarTypes.LEFT_SIDEBAR_TYPE_FULLSCREEN;
  showTwoToneIcons: boolean;
  showSidebarUserInfo: boolean;
  topbarTheme: TopbarTheme.TOPBAR_THEME_LIGHT | TopbarTheme.TOPBAR_THEME_DARK | TopbarTheme.TOPBAR_THEME_BRAND;
  isOpenRightSideBar: boolean;
  showSpinner: boolean;
  mensajeModal: {
    mensaje: string,
    tipo: TipoMensaje,
    show: boolean
  }
  mensajeConfirmacionModal: {
    mensaje: string,
    show: boolean,
    confirmar: () => void
  }
  usuarioModal: {
    mode: UsuarioModalMode,
    show: boolean,
    usuario: UsuarioResponse | {},
    modulos: BaseResponse[]
  }
  materiaPrimaModal: {
    mode: MateriaPrimaModalMode,
    show: boolean,
    materiaPrima: MateriaPrimaResponse | {},
  }
}

export { LayoutActionTypes };
