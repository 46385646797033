import React, { useCallback, useState } from "react";
import { Button, Container, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { BaseResponse } from "../../common/BaseResponse";
import Table from "../../components/Table";
import { insert, update } from "../../redux/actions";
import { AppDispatch } from "../../redux/store";
import { UsuarioResponse } from "../../responses/usuario/UsuarioResponse";
import { FormInput, VerticalForm } from "../../components";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

interface UsuarioModalProps {
    show: boolean,
    toggle: () => void,
    mode: UsuarioModalMode,
    usuario: UsuarioResponse,
    perfiles: BaseResponse[],
    modulos: BaseResponse[]
}

interface UsuarioData {
    nombres: string,
    apellidos: string
}

export enum UsuarioModalMode {
    Visualizar,
    Registrar,
    Modificar
}

const Modulo = (p: any) => {
    return (
        <React.Fragment>
            {p.nombre}
        </React.Fragment>
    )
}

const sizePerPageList = [
    {
        text: "10",
        value: 10,
    },
    {
        text: "25",
        value: 25,
    },
];


const UsuarioModal = (props: UsuarioModalProps) => {
    const [usuario, setUsuario] = useState<any>({ id: props['usuario'].id, nombres: props['usuario'].nombres, apellidos: props['usuario'].apellidos, correo: props['usuario'].correo, celular: props['usuario'].celular, dni: props['usuario'].dni, perfiles: props['usuario'].perfiles, permisos: props['usuario'].permisos });
    const mode = props['mode'];
    const ModuloColumn = ({ row }: { row: any }) => {
        const p = props['modulos'].find(r => r.id === row.original.idModulo) || { id: 0, nombre: '' }
        return Modulo(p);
    };

    const setVisualizar = useCallback((row: any) => {
        let permisos = usuario.permisos;
        for (let permiso of usuario.permisos) {
            if (permiso.idModulo === row.idModulo) {
                permiso.visualizar = !row.visualizar;
                break;
            }
        }
        setUsuario({ ...usuario, permisos });
    }, [usuario]);

    const setRegistrar = useCallback((row: any) => {
        let permisos = usuario.permisos;
        for (let permiso of usuario.permisos) {
            if (permiso.idModulo === row.idModulo) {
                permiso.registrar = !row.registrar;
                break;
            }
        }
        setUsuario({ ...usuario, permisos });
    }, [usuario]);

    const setModificar = useCallback((row: any) => {
        let permisos = usuario.permisos;
        for (let permiso of usuario.permisos) {
            if (permiso.idModulo === row.idModulo) {
                permiso.modificar = !row.modificar;
                break;
            }
        }
        setUsuario({ ...usuario, permisos });
    }, [usuario]);

    const setEliminar = useCallback((row: any) => {
        let permisos = usuario.permisos;
        for (let permiso of usuario.permisos) {
            if (permiso.idModulo === row.idModulo) {
                permiso.eliminar = !row.eliminar;
                break;
            }
        }
        setUsuario({ ...usuario, permisos });
    }, [usuario]);

    const VisualizarColumn = ({ row }: { row: any }) => {
        return (
            <div className="d-flex justify-content-center">
                <input type="checkbox" disabled={mode === UsuarioModalMode.Visualizar} className="form-check-input" checked={row.original.visualizar} onChange={() => setVisualizar(row.original)} />
            </div>
        );
    };

    const RegistrarColumn = ({ row }: { row: any }) => {
        return (
            <div className="d-flex justify-content-center">
                <input type="checkbox" disabled={mode === UsuarioModalMode.Visualizar} className="form-check-input" checked={row.original.registrar} onChange={() => setRegistrar(row.original)} />
            </div>
        );
    };

    const ModificarColumn = ({ row }: { row: any }) => {
        return (
            <div className="d-flex justify-content-center">
                <input type="checkbox" disabled={mode === UsuarioModalMode.Visualizar} className="form-check-input" checked={row.original.modificar} onChange={() => setModificar(row.original)} />
            </div>
        );
    };

    const EliminarColumn = ({ row }: { row: any }) => {
        return (
            <div className="d-flex justify-content-center">
                <input type="checkbox" disabled={mode === UsuarioModalMode.Visualizar} className="form-check-input" checked={row.original.eliminar} onChange={() => setEliminar(row.original)} />
            </div>
        );
    };

    const columns = [
        {
            Header: "Modulo",
            accessor: "idModulo",
            sort: false,
            Cell: ModuloColumn
        },
        {
            Header: "Visualizar",
            accessor: "visualizar",
            sort: false,
            classes: "text-center",
            Cell: VisualizarColumn
        },
        {
            Header: "Registrar",
            accessor: "registrar",
            sort: false,
            classes: "text-center",
            Cell: RegistrarColumn
        },
        {
            Header: "Modificar",
            accessor: "modificar",
            sort: false,
            classes: "text-center",
            Cell: ModificarColumn
        },
        {
            Header: "Eliminar",
            accessor: "eliminar",
            sort: false,
            classes: "text-center",
            Cell: EliminarColumn
        },
    ];

    const dispatch = useDispatch<AppDispatch>();

    const save = useCallback(() => {
        console.log('sdadsasd')
        if (mode === UsuarioModalMode.Registrar) {
            dispatch(insert({ nombres: usuario.nombres, apellidos: usuario.apellidos, correo: usuario.correo, celular: usuario.celular, dni: usuario.dni, foto: usuario.foto, perfiles: usuario.perfiles, permisos: usuario.permisos }))
        } else if (mode === UsuarioModalMode.Modificar) {
            dispatch(update({ id: usuario.id, nombres: usuario.nombres, apellidos: usuario.apellidos, correo: usuario.correo, celular: usuario.celular, dni: usuario.dni, foto: usuario.foto, perfiles: usuario.perfiles, permisos: usuario.permisos }))
        }
    }, [dispatch, usuario, mode])

    const setPerfiles = useCallback((e: any) => {
        setUsuario({ ...usuario, perfiles: e.map((e: any) => e.value) })
    }, [usuario])

    const schemaResolver = yupResolver(
        yup.object({
            nombres: yup.string().required("Se requiere un nombre").min(3, "min"),
            apellidos: yup.string().required("Seleccione una opción"),
            correo: yup.string().required("Se requiere un correo"),
            celular: yup.string().required("Se requiere un celular").matches(/^[0-9]+$/, "Solo números"),
            dni: yup.string().required("Se requiere un dni").matches(/^[0-9]+$/, "Solo números").length(8,"Debe contener 8 dígitos"),
            perfiles: yup.array().min(1,"Se requiere por lo menos un perfil de usuario")
        })
    );
    return (
        <Modal show={props['show']} onHide={props['toggle']} size={"lg"} animation={false}>
            <Modal.Header onHide={props['toggle']} closeButton>
                <h4 className="modal-title">{UsuarioModalMode[props['mode']]} Usuario</h4>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <VerticalForm<UsuarioData>
                        onSubmit={save}
                        resolver={schemaResolver}
                        formClass="row">
                        <FormInput
                            label="Nombres"
                            type="text"
                            name="nombres"
                            containerClass={"mb-3 col-6"}
                            disabled={props['mode'] === UsuarioModalMode.Visualizar}
                            value={usuario.nombres}
                            onChange={(e) => setUsuario((prevState: any) => ({ ...prevState, nombres: e.target.value }))}
                        />
                        <FormInput
                            label="Apellidos"
                            type="text"
                            name="apellidos"
                            containerClass={"mb-3 col-6"}
                            disabled={props['mode'] === UsuarioModalMode.Visualizar}
                            value={usuario.apellidos}
                            onChange={(e) => setUsuario({ ...usuario, apellidos: e.target.value })}
                        />
                        <FormInput
                            label="Correo"
                            type="text"
                            name="correo"
                            containerClass={"mb-3 col-6"}
                            disabled={props['mode'] === UsuarioModalMode.Visualizar}
                            value={usuario.correo}
                            onChange={(e) => setUsuario({ ...usuario, correo: e.target.value })}
                        />
                        <FormInput
                            label="Celular"
                            type="text"
                            name="celular"
                            containerClass={"mb-3 col-6"}
                            disabled={props['mode'] === UsuarioModalMode.Visualizar}
                            value={usuario.celular}
                            onChange={(e) => setUsuario({ ...usuario, celular: e.target.value })}
                        />
                        <FormInput
                            label="Dni"
                            type="text"
                            name="dni"
                            max={8}
                            containerClass={"mb-3 col-6"}
                            disabled={props['mode'] === UsuarioModalMode.Visualizar}
                            value={usuario.dni}
                            onChange={(e) => setUsuario({ ...usuario, dni: e.target.value })}
                        />
                        <FormInput
                            label="Perfiles"
                            type="select"
                            name="perfiles"
                            containerClass={"col-6 mb-2"}
                            isMulti={true}
                            isDisabled={props['mode'] === UsuarioModalMode.Visualizar}
                            hideSelectedOptions={false}
                            closeMenuOnSelect={false}
                            onChange={setPerfiles}
                            options={props['perfiles'].map((perfil: BaseResponse) => { return { value: perfil.id, label: perfil.nombre } })}
                            value={props['perfiles'].filter((perfil: BaseResponse) => usuario.perfiles.includes(perfil.id)).map((perfil: BaseResponse) => { return { value: perfil.id, label: perfil.nombre } })}
                        ></FormInput>
                        <div className="mb-3">
                            <label className="form-label">Foto</label>
                            <input className="form-control" disabled={props['mode'] === UsuarioModalMode.Visualizar} id="formFileSm" onChange={(e) => setUsuario({ ...usuario, foto: e.target.files !== null ? e.target.files[0] : null })} type="file" />
                        </div>
                        <Table
                            columns={columns}
                            data={props['usuario'].permisos}
                            pageSize={10}
                            sizePerPageList={sizePerPageList}
                            isSortable={true}
                            pagination={true}
                            isSelectable={true}
                            isSearchable={true}
                            tableClass="table-striped dt-responsive nowrap w-100"
                            searchBoxClass="my-2"
                        />
                        <div className="text-end">
                            <Button variant="light" onClick={props['toggle']}>
                                Cerrar
                            </Button>{" "}
                            {props['mode'] !== UsuarioModalMode.Visualizar && <Button variant="primary" type="submit">
                                Guardar
                            </Button>}
                        </div>
                    </VerticalForm>
                </Container>
            </Modal.Body>
        </Modal>
    )
};

export default UsuarioModal;