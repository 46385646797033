export enum UsuarioActionTypes {
  API_RESPONSE_SUCCESS = "@@usuario/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@usuario/API_RESPONSE_ERROR",

  LOGIN_DASHBOARD_USUARIO = "@@usuario/LOGIN_DASHBOARD_USUARIO",
  GET_PAGINATE_USUARIO = "@@usuario/GET_PAGINATE_USUARIO",
  GET_PERFILES_USUARIO = "@@usuario/GET_PERFILES_USUARIO",
  INSERT_USUARIO = "@@usuario/INSERT_USUARIO",
  UPDATE_USUARIO = "@@usuario/UPDATE_USUARIO",
  DELETE_USUARIO = "@@usuario/DELETE_USUARIO",
  RESET = "@@usuario/RESET",
}
