import classNames from "classnames"
import { Modal } from "react-bootstrap"

interface MensajeModalProps {
    show: boolean,
    toggle: () => void,
    titulo?: string,
    mensaje: string,
    tipoMensaje: TipoMensaje
}

export enum TipoMensaje {
    success,
    danger
}

export const MensajeModal = (props: MensajeModalProps) => {
    const titulo = props['titulo'] || 'Mensaje'
    return (
        <Modal show={props['show']} onHide={props['toggle']} size={"sm"} animation={false} centered style={{ zIndex: '1100' }} backdropClassName="modal-mensaje" >
            <div className={classNames("modal-filled", "bg-" + TipoMensaje[props['tipoMensaje']])}>
                <Modal.Body className="p-4">
                    <div className="text-center">
                        <i className="dripicons-checkmark h1 text-white"></i>
                        <h4 className="mt-2 text-white">{titulo}</h4>
                        <p className="mt-3 text-white">
                            {props['mensaje']}
                        </p>
                        <button
                            type="button"
                            onClick={props['toggle']}
                            className="btn btn-light my-2"
                        >
                            Continuar
                        </button>
                    </div>
                </Modal.Body>
            </div>
        </Modal>
    )
}