import { Button, Modal } from "react-bootstrap"

interface MensajeConfirmacionModalProps {
    show: boolean,
    toggle: () => void,
    confirmar: () => void,
    titulo?: string,
    mensaje?: string,
}

export const MensajeConfirmacionModal = (props: MensajeConfirmacionModalProps) => {
    const titulo = props['titulo'] || 'Confirmar acción'
    const mensaje = props['mensaje'] || '¿Está Seguro de que desea realizar esta acción?'
    return (
        <Modal show={props['show']} onHide={props['toggle']} dialogClassName="modal-80w" animation={false} centered style={{ zIndex: '1100' }} backdropClassName="modal-mensaje" >
            <div className="modal-filled bg-light">
                <Modal.Body className="p-4">
                    <div className="text-center">
                        <i className="dripicons-checkmark h1 text-dark"></i>
                        <h4 className="mt-2 text-dark">{titulo}</h4>
                        <p className="mt-3 text-dark">
                            {mensaje}
                        </p>
                        <Button
                            variant={"danger"}
                            onClick={props['toggle']}
                            className="m-2"
                        >
                            Cancelar
                        </Button>
                        <Button
                            variant={"primary"}
                            onClick={props['confirmar']}
                            className="m-2"
                        >
                            Confirmar
                        </Button>
                    </div>
                </Modal.Body>
            </div>
        </Modal>
    )
}