import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// apicore
import { APICore, setAuthorization } from "../../helpers/api/apiCore";

// helpers
import { Usuario } from "../../helpers";

// actions
import { usuarioApiResponseSuccess, usuarioApiResponseError } from "./actions";

// constants
import { UsuarioActionTypes } from "./constants";
import { GetAllPaginateUsuarioRequest } from "../../requests/usuario/GetAllPaginateUsuarioRequest";
import { InsertUsuarioRequest } from "../../requests/usuario/InsertUsuarioRequest";
import { UpdateUsuarioRequest } from "../../requests/usuario/UpdateUsuarioRequest";
import { closeMensajeConfirmacionModal, openMensajeModal, toggleSpinner } from "../layout/actions";
import { TipoMensaje } from "../../components/modals/MensajeModal";

interface GetAllPaginateData {
  payload: GetAllPaginateUsuarioRequest;
  type: string;
}

interface InsertData {
  payload: InsertUsuarioRequest
  type: string;
}

interface UpdateData {
  payload: UpdateUsuarioRequest
  type: string;
}

interface DeleteData {
  payload: { id: number }
  type: string;
}

interface LoginDashboardData {
  payload: { correo: string, password: string },
  type: string
}

const api = new APICore();

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* loginDashboard({
  payload: { correo, password },
  type,
}: LoginDashboardData): SagaIterator {
  try {
    const response = yield call(Usuario.loginDashboard, { correo, password });
    const { token } = response.data;
    api.setLoggedInUser(token);
    setAuthorization(token);
    yield put(usuarioApiResponseSuccess(UsuarioActionTypes.LOGIN_DASHBOARD_USUARIO, token));
  } catch (error: any) {
    yield put(usuarioApiResponseError(UsuarioActionTypes.LOGIN_DASHBOARD_USUARIO, error));
    api.setLoggedInUser(null);
    setAuthorization(null);
  }
}

/**
 * Lista los usuarios
 * @param {*} payload
 */
 function* getPaginate(request: GetAllPaginateData): SagaIterator {
  try {
    const response = yield call(Usuario.getPaginate, request.payload);
    yield put(usuarioApiResponseSuccess(UsuarioActionTypes.GET_PAGINATE_USUARIO, response.data ));
  } catch (error: any) {
    yield put(usuarioApiResponseError(UsuarioActionTypes.GET_PAGINATE_USUARIO, error));
  }
}

/**
 * Lista los perfiles
 */
 function* getPerfiles(): SagaIterator {
  try {
    const response = yield call(Usuario.getPerfiles);
    yield put(usuarioApiResponseSuccess(UsuarioActionTypes.GET_PERFILES_USUARIO, response.data));
  } catch (error: any) {
    yield put(usuarioApiResponseError(UsuarioActionTypes.GET_PERFILES_USUARIO, error));
  }
}

/**
 * Registra un usuario
 */
 function* insert({ payload, type }: InsertData): SagaIterator {
  try {
    yield put(toggleSpinner(true))
    const response = yield call(Usuario.insert, payload);
    yield put(openMensajeModal(TipoMensaje.success, response.data.message))
  } catch (error: any) {
    yield put(openMensajeModal(TipoMensaje.danger, error))
  }
}

/**
 * Registra un usuario
 */
 function* update({ payload, type }: UpdateData): SagaIterator {
  try {
    yield put(toggleSpinner(true))
    const response = yield call(Usuario.update, payload);
    yield put(openMensajeModal(TipoMensaje.success, response.data.message))
  } catch (error: any) {
    yield put(openMensajeModal(TipoMensaje.danger, error))
  } finally {
    yield put(toggleSpinner(false));
  }
}

/**
 * Registra un usuario
 */
 function* deleteUsuario({ payload, type }: DeleteData): SagaIterator {
  try {
    yield put(closeMensajeConfirmacionModal());
    yield put(toggleSpinner(true));
    const response = yield call(Usuario.remove, payload);
    yield put(openMensajeModal(TipoMensaje.success, response.data.message))
  } catch (error: any) {
    yield put(openMensajeModal(TipoMensaje.danger, error))
  } finally {
    yield put(toggleSpinner(false));
  }
}

export function* watchLoginDashboardUsuario() {
  yield takeEvery(UsuarioActionTypes.LOGIN_DASHBOARD_USUARIO, loginDashboard);
}

export function* watchGetPaginateUsuario() {
  yield takeEvery(UsuarioActionTypes.GET_PAGINATE_USUARIO, getPaginate);
}

export function* watchGetPerfilesUsuario() {
  yield takeEvery(UsuarioActionTypes.GET_PERFILES_USUARIO, getPerfiles);
}

export function* watchInsertUsuario() {
  yield takeEvery(UsuarioActionTypes.INSERT_USUARIO, insert);
}

export function* watchUpdateUsuario() {
  yield takeEvery(UsuarioActionTypes.UPDATE_USUARIO, update);
}

export function* watchDeleteUsuario() {
  yield takeEvery(UsuarioActionTypes.DELETE_USUARIO, deleteUsuario);
}

function* usuarioSaga() {
  yield all([
    fork(watchLoginDashboardUsuario),fork(watchGetPaginateUsuario),fork(watchGetPerfilesUsuario),fork(watchInsertUsuario),fork(watchUpdateUsuario),fork(watchDeleteUsuario)
  ]);
}

export default usuarioSaga;
