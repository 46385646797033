// apicore
import { BaseResponse } from "../../common/BaseResponse";
import { PaginateResponse } from "../../common/PaginateResponse";
import { TipoMensaje } from "../../components/modals/MensajeModal";
import { MateriasPrimasResponse } from "../../responses/materiaPrima/MateriasPrimasResponse";
import { TarifasMateriaPrimaResponse } from "../../responses/materiaPrima/TarifasMateriaPrimaResponse";
import { UsuarioResponse } from "../../responses/usuario/UsuarioResponse";

// constants
import { MateriaPrimaActionTypes } from "./constants";

const INIT_STATE = {
  materiasPrimas: { results: [], totalRows: 0 },
  materiaPrima: { id: 0, nombre: '', descripcion: '', imagen: '', idSubCategoriaMateriaPrima: 0, idColor: 0, detalles: [] },
  materiasPrimasCombo: [],
  tarifas: { results: [], totalRows: 0 },
  loadingTarifas: false,
  tipos: [],
  colores: [],
  categorias: [],
  subCategorias: [],
  showMateriaPrimaModal: false,
  showMensaje: false,
  loadingMateriasPrimas: false,
  loadingColores: false,
  loadingTipos: false,
  loadingCategorias: false,
  loadingSubCategorias: false,
  loadingModal: false,
  loadingInsert: false,
  loadingUpdate: false
};

interface UsuarioActionType {
  type:
  | MateriaPrimaActionTypes.API_RESPONSE_SUCCESS
  | MateriaPrimaActionTypes.API_RESPONSE_ERROR
  | MateriaPrimaActionTypes.GET_PAGINATE_MATERIA_PRIMA
  | MateriaPrimaActionTypes.GET_TARIFAS_PAGINATE_MATERIA_PRIMA
  | MateriaPrimaActionTypes.INSERT_MATERIA_PRIMA
  | MateriaPrimaActionTypes.UPDATE_MATERIA_PRIMA
  payload: {
    actionType?: string;
    data: any;
    error?: any;
  };
}

interface State {
  materiasPrimas: PaginateResponse<MateriasPrimasResponse> | {};
  materiasPrimasCombo: BaseResponse[];
  tarifas: PaginateResponse<TarifasMateriaPrimaResponse> | {};
  loadingTarifas: boolean;
  materiaPrima: UsuarioResponse | {};
  tipos: any[];
  colores: any[];
  categorias: any[];
  subCategorias: any[];
  loadingMateriasPrimas: boolean;
  loadingColores: boolean;
  loadingTipos: boolean;
  loadingCategorias: boolean;
  loadingSubCategorias: boolean;
  loadingModal: boolean;
  loadingInsert: boolean;
  loadingUpdate: boolean;
  showMateriaPrimaModal: boolean;
  showMensaje: boolean;
}

const MateriaPrima = (state: State = INIT_STATE, action: UsuarioActionType): any => {
  switch (action.type) {
    case MateriaPrimaActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case MateriaPrimaActionTypes.GET_PAGINATE_MATERIA_PRIMA: {
          return {
            ...state,
            materiasPrimas: action.payload.data,
            loadingMateriasPrimas: false,
          };
        }
        case MateriaPrimaActionTypes.GET_TARIFAS_PAGINATE_MATERIA_PRIMA: {
          return {
            ...state,
            tarifas: action.payload.data,
            loadingTarifas: false,
          };
        }
        case MateriaPrimaActionTypes.GET_COLORES_MATERIA_PRIMA: {
          return {
            ...state,
            colores: action.payload.data,
            loadingColores: false,
          };
        }
        case MateriaPrimaActionTypes.GET_TIPOS_MATERIA_PRIMA: {
          return {
            ...state,
            tipos: action.payload.data,
            categorias: INIT_STATE.categorias,
            subCategorias: INIT_STATE.subCategorias,
            loadingTipos: false,
          };
        }
        case MateriaPrimaActionTypes.GET_CATEGORIAS_MATERIA_PRIMA: {
          return {
            ...state,
            categorias: action.payload.data,
            subCategorias: INIT_STATE.subCategorias,
            loadingCategorias: false,
          };
        }
        case MateriaPrimaActionTypes.GET_SUB_CATEGORIAS_MATERIA_PRIMA: {
          return {
            ...state,
            subCategorias: action.payload.data,
            loadingSubCategorias: false,
          };
        }
        case MateriaPrimaActionTypes.INSERT_MATERIA_PRIMA: {
          return {
            ...state,
            usuario: INIT_STATE.materiaPrima,
            showMateriaPrimaModal: false,
            showMensaje: true,
            loadingInsert: false,
            loadingModal: false,
            mensaje: action.payload.data.message,
            tipoMensaje: TipoMensaje.success
          };
        }
        case MateriaPrimaActionTypes.UPDATE_MATERIA_PRIMA: {
          return {
            ...state,
            materiaPrima: INIT_STATE.materiaPrima,
            showMateriaPrimaModal: false,
            loadingUpdate: false,
            mensaje: action.payload.data.message
          };
        }
        default:
          return { ...state };
      }

    case MateriaPrimaActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case MateriaPrimaActionTypes.GET_PAGINATE_MATERIA_PRIMA: {
          return {
            ...state,
            error: action.payload.error,
            loadingMateriaPrima: false,
          };
        }
        case MateriaPrimaActionTypes.GET_TARIFAS_PAGINATE_MATERIA_PRIMA: {
          return {
            ...state,
            error: action.payload.error,
            loadingTarifas: false,
          };
        }
        case MateriaPrimaActionTypes.INSERT_MATERIA_PRIMA: {
          return {
            ...state,
            loadingInsert: false,
            loadingModal: false,
            showMensaje: true,
            mensaje: action.payload.error,
            tipoMensaje: TipoMensaje.danger
          };
        }
        case MateriaPrimaActionTypes.UPDATE_MATERIA_PRIMA: {
          return {
            ...state,
            loadingUpdate: false,
            mensaje: action.payload.error,
          };
        }
        default:
          return { ...state };
      }
    case MateriaPrimaActionTypes.GET_PAGINATE_MATERIA_PRIMA:
      return { ...state, loadingMateriasPrimas: true };
    case MateriaPrimaActionTypes.GET_TARIFAS_PAGINATE_MATERIA_PRIMA:
        return { ...state, loadingTarifas: true };
    case MateriaPrimaActionTypes.INSERT_MATERIA_PRIMA:
      return { ...state, loadingInsert: true, loadingModal: true, };
    case MateriaPrimaActionTypes.UPDATE_MATERIA_PRIMA:
      return { ...state, loadingUpdate: true };
    default:
      return { ...state };
  }
};

export default MateriaPrima;