import React, { useCallback, useState } from "react";
import { Button, Container, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { BaseResponse } from "../../common/BaseResponse";
import { getCategoriasByIdTipoMateriaPrima, getSubCategoriasByIdCategoriaMateriaPrima, insertMateriaPrima, updateMateriaPrima } from "../../redux/actions";
import { AppDispatch } from "../../redux/store";
import { FormInput, VerticalForm } from "../../components";
import { MateriaPrimaResponse } from "../../responses/materiaPrima/MateriaPrimaResponse";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

interface MateriaPrimaModalProps {
    show: boolean,
    toggle: () => void,
    mode: MateriaPrimaModalMode,
    materiaPrima: MateriaPrimaResponse,
    tipos: BaseResponse[],
    categorias: BaseResponse[],
    subCategorias: BaseResponse[],
    colores: BaseResponse[]
}

interface MateriaPrimaData {
    nombre: string,
    descripcion: string,
    subCategoria: any,
    categoria: any,
    tipo: any
}

export enum MateriaPrimaModalMode {
    Visualizar,
    Registrar,
    Modificar
}

const schemaResolver = yupResolver(
    yup.object({
        nombre: yup.string().required("Se requiere un nombre").min(3, "min"),
        tipo: yup.array().required("Seleccione una opción").length(1, "Seleccione una opción"),
        categoria: yup.array().required("Seleccione una opción").length(1, "Seleccione una opción"),
        subCategoria: yup.array().required("Seleccione una opción").length(1, "Seleccione una opción"),
        color: yup.array().required("Seleccione una opción").length(1, "Seleccione una opción")
    })
);

const MateriaPrimaModal = (props: MateriaPrimaModalProps) => {
    const [materiaPrima, setMateriaPrima] = useState<any>({ nombre: props['materiaPrima'].nombre, descripcion: props['materiaPrima'].descripcion, idColor: props['materiaPrima'].idColor, idSubCategoriaMateriaPrima: props['materiaPrima'].idSubCategoriaMateriaPrima, idCategoriaMateriaPrima: props['materiaPrima'].idCategoriaMateriaPrima, idTipoMateriaPrima: props['materiaPrima'].idTipoMateriaPrima, id: props['materiaPrima'].id });
    const mode = props['mode'];

    const dispatch = useDispatch<AppDispatch>();

    const save = useCallback(() => {
        if (mode === MateriaPrimaModalMode.Registrar) {
            dispatch(insertMateriaPrima({ nombre: materiaPrima.nombre, descripcion: materiaPrima.descripcion, idSubCategoriaMateriaPrima: materiaPrima.idSubCategoriaMateriaPrima, idColor: materiaPrima.idColor }))
        } else if (mode === MateriaPrimaModalMode.Modificar) {
            dispatch(updateMateriaPrima({ id: materiaPrima.id, nombre: materiaPrima.nombre, descripcion: materiaPrima.descripcion, idSubCategoriaMateriaPrima: materiaPrima.idSubCategoriaMateriaPrima, idColor: materiaPrima.idColor }))
        }
    }, [dispatch, materiaPrima, mode])

    const setTipo = useCallback((e: any) => {
        setMateriaPrima((prevState: any) => ({ ...prevState, idTipoMateriaPrima: e.value }))
        dispatch(getCategoriasByIdTipoMateriaPrima(e.value))
    }, [dispatch])

    const setCategoria = useCallback((e: any) => {
        setMateriaPrima((prevState: any) => ({ ...prevState, idCategoriaMateriaPrima: e.value }))
        dispatch(getSubCategoriasByIdCategoriaMateriaPrima(e.value))
    }, [dispatch])

    const setSubCategoria = useCallback((e: any) => {
        setMateriaPrima((prevState: any) => ({ ...prevState, idSubCategoriaMateriaPrima: e.value }))
    }, [])

    const setColor = useCallback((e: any) => {
        setMateriaPrima((prevState: any) => ({ ...prevState, idColor: e.value }))
    }, [])

    return (
        <Modal show={props['show']} onHide={props['toggle']} size={"lg"} animation={false}>
            <Modal.Header onHide={props['toggle']} closeButton>
                <h4 className="modal-title">{MateriaPrimaModalMode[props['mode']]} Materia Prima</h4>
            </Modal.Header>

            <Modal.Body>
                <Container>
                    <VerticalForm<MateriaPrimaData>
                        onSubmit={save}
                        resolver={schemaResolver}
                        formClass="row"
                    >
                        <FormInput
                            label="Nombre"
                            type="text"
                            name="nombre"
                            containerClass={"col-6 mb-2"}
                            disabled={props['mode'] === MateriaPrimaModalMode.Visualizar}
                            value={materiaPrima.nombre}
                            onChange={(e) => setMateriaPrima((prevState: any) => ({ ...prevState, nombre: e.target.value }))}
                        />
                        <FormInput
                            label="Tipo"
                            type="select"
                            name="tipo"
                            containerClass={"col-6 mb-2"}
                            isDisabled={props['mode'] === MateriaPrimaModalMode.Visualizar}
                            hideSelectedOptions={false}
                            closeMenuOnSelect={true}
                            onChange={setTipo}
                            options={props['tipos'].map((tipo: BaseResponse) => { return { value: tipo.id, label: tipo.nombre } })}
                            value={props['tipos'].filter((tipo: BaseResponse) => materiaPrima.idTipoMateriaPrima === tipo.id).map((tipo: BaseResponse) => { return { value: tipo.id, label: tipo.nombre } })}
                        >
                        </FormInput>
                        <FormInput
                            label="Categoria"
                            type="select"
                            name="categoria"
                            containerClass={"col-6 mb-2"}
                            isDisabled={props['mode'] === MateriaPrimaModalMode.Visualizar}
                            hideSelectedOptions={false}
                            closeMenuOnSelect={true}
                            onChange={setCategoria}
                            options={props['categorias'].map((categoria: BaseResponse) => { return { value: categoria.id, label: categoria.nombre } })}
                            value={props['categorias'].filter((categoria: BaseResponse) => materiaPrima.idCategoriaMateriaPrima === categoria.id).map((categoria: BaseResponse) => { return { value: categoria.id, label: categoria.nombre } })}
                        >
                        </FormInput>
                        <FormInput
                            label="Sub Categoria"
                            type="select"
                            name="subCategoria"
                            containerClass={"col-6 mb-2"}
                            isDisabled={props['mode'] === MateriaPrimaModalMode.Visualizar}
                            hideSelectedOptions={false}
                            closeMenuOnSelect={true}
                            onChange={setSubCategoria}
                            options={props['subCategorias'].map((subCategoria: BaseResponse) => { return { value: subCategoria.id, label: subCategoria.nombre } })}
                            value={props['subCategorias'].filter((subCategoria: BaseResponse) => materiaPrima.idSubCategoriaMateriaPrima === subCategoria.id).map((subCategoria: BaseResponse) => { return { value: subCategoria.id, label: subCategoria.nombre } })}
                        >
                        </FormInput>
                        <FormInput
                            label="Color"
                            type="select"
                            name="color"
                            containerClass={"col-6 mb-2"}
                            isDisabled={props['mode'] === MateriaPrimaModalMode.Visualizar}
                            hideSelectedOptions={false}
                            closeMenuOnSelect={true}
                            onChange={setColor}
                            options={props['colores'].map((color: BaseResponse) => { return { value: color.id, label: color.nombre } })}
                            value={props['colores'].filter((color: BaseResponse) => materiaPrima.idColor === color.id).map((color: BaseResponse) => { return { value: color.id, label:  color.nombre } })}
                        >
                        </FormInput>
                        <FormInput
                            label="Descripcion"
                            type="textarea"
                            name="descripcion"
                            rows="3"
                            containerClass={"mb-2"}
                            disabled={props['mode'] === MateriaPrimaModalMode.Visualizar}
                            value={materiaPrima.descripcion}
                            onChange={(e) => setMateriaPrima((prevState: any) => ({ ...prevState, descripcion: e.target.value }))}
                        />
                        <div className="text-end">
                            <Button variant="light" onClick={props['toggle']}>
                                Cerrar
                            </Button>{" "}
                            {props['mode'] !== MateriaPrimaModalMode.Visualizar && <Button variant="primary" type="submit">
                                Guardar
                            </Button>}
                        </div>
                    </VerticalForm>
                </Container>
            </Modal.Body>
        </Modal>
    )
};

export default MateriaPrimaModal;